import React, { useState } from "react";

function Expertise() {
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      <div className="section-ekspertiz-nedir">
        <div className="container">
          <div className=" seotext">
            <p class="p1">
              <strong>
                Oto Eksper &ndash; Otorapor Ekspertiz Firması | &Ccedil;ankaya,
                ANKARA
              </strong>
            </p>
            <p class="p1">
              <strong>Oto eksper</strong>, ara&ccedil;ların hasar tespiti,
              değerlendirmesi ve raporlanması gibi konularda uzmanlaşmış
              profesyonelleri ifade eder. Oto eksperler, genellikle sigorta
              şirketleri, hukuk firmaları, otomobil galerileri veya bireysel
              m&uuml;şteriler i&ccedil;in &ccedil;alışabilirler.
            </p>
            <p class="p2">
              <strong>&Ccedil;ankaya, ANKARA</strong>&rsquo;da g&uuml;venilir
              oto eksper firması arıyorsanız bizimle{" "}
              <a href="tel:+905424425060">
                <span class="s1">iletişime</span>
              </a>{" "}
              ge&ccedil;ebilirsiniz.
            </p>
            <p class="p1">
              <strong>Oto Eksper Nedir?</strong>
            </p>
            <p class="p1">
              Otomobil eksper, otomobillerin değerlendirilmesi, incelenmesi ve
              hasar tespiti gibi konularda uzmanlaşmış profesyonelleri ifade
              eder. Bu uzmanlar genellikle ara&ccedil; alım satımı, sigorta
              talepleri, hukuki anlaşmazlıklar ve diğer benzeri durumlar
              i&ccedil;in hizmet verirler.
            </p>
            <p class="p1">
              Otomobil eksperleri genellikle otomotiv sekt&ouml;r&uuml;nde uzun
              yıllara dayanan deneyime ve ilgili eğitime sahip
              profesyonellerdir. Bu kişiler ara&ccedil; mekaniği, otomobil
              m&uuml;hendisliği veya ilgili alanlarda eğitim almışlardır.
              Ayrıca, sekt&ouml;rdeki teknolojik gelişmeleri ve yasal
              d&uuml;zenlemeleri yakından takip ederler.
            </p>
            <p class="p1">
              <strong>Ankara/ &Ccedil;ankaya Oto Eksper</strong>
            </p>
            <p class="p1">
              <strong>Ankara&rsquo;da oto eksper hizmeti</strong> i&ccedil;in
              bize{" "}
              <a href="tel:+905424425060">
                <span class="s1">ulaşabilirsiniz</span>
              </a>
              . Peki size neler sunuyoruz?
            </p>
            <ul class="ul1">
              <li class="li1">
                G&uuml;venilir ve Tarafsız Hizmet: OtoRapor, ara&ccedil;
                alıcıları ve satıcılarına adil bir platform sağlayarak,
                ara&ccedil; alıcısının ara&ccedil;la ilgili doğru bilgilere
                ulaşma hakkını korur. OtoRapor'un sağladığı hizmetler,
                alıcıların ara&ccedil;lar hakkında g&uuml;venilir bilgilere
                erişmesini sağlayarak satın alma s&uuml;recini destekler.
              </li>
              <li class="li1">
                Y&uuml;ksek Kalite Standartları: OtoRapor'un t&uuml;m şubeleri,
                T&uuml;rk Standartları Enstit&uuml;s&uuml;'n&uuml;n ara&ccedil;
                ekspertiz merkezleri i&ccedil;in belirlediği standartları
                titizlikle takip eder ve Kalite Y&ouml;netim Sistemi
                prensiplerine sıkı bir şekilde uyar. Bu sayede, m&uuml;şterilere
                g&uuml;venilir ve kaliteli hizmet sunmayı ama&ccedil;lar ve
                s&uuml;rekli olarak iyileştirme ve gelişmeyi hedefler.
              </li>
              <li class="li1">
                OTORAPORLAMA ve Raporların Saklanması: OTORAPORLAMA,
                ara&ccedil;la ilgili t&uuml;m verilerin hatasız bir şekilde
                raporlanması ve bu raporların dijital ortamda minimum 5 yıl
                s&uuml;reyle saklanması işlemidir. OtoRapor, yaklaşık 1.2 milyon
                aracın verisini dijital ortamda saklayarak, bu sayıyı
                s&uuml;rekli olarak artırmaktadır. Bu sayede, ge&ccedil;miş
                ara&ccedil; raporlarına erişim sağlanabilir ve m&uuml;şterilere
                daha kapsamlı bir hizmet sunulabilir.
              </li>
            </ul>
            <p class="p1">
              <strong>Oto Eksper Paketleri</strong>
            </p>
            <p class="p1">
              Ankara&rsquo;da oto eksper paketleri aşağıdadır. Fiyat ve detaylı
              bilgi i&ccedil;in bizi{" "}
              <a href="tel:+905424425060">
                <span class="s1">arayınız</span>
              </a>
              .
            </p>
            <ul class="ul1">
              <li class="li1">BRONZ PAKET</li>
              <li class="li1">SILVER PAKET</li>
              <li class="li1">SILVER PLUS PAKET</li>
              <li class="li1">GOLD PAKET</li>
              <li class="li1">GOLD PLUS PAKET</li>
              <li class="li1">FULL PAKET</li>
              <li class="li1">GARANTİLİ FULL PAKET</li>
              <li class="li1">LUXURY PAKET</li>
              <li class="li1">VIP HAVAALANI PAKETİ</li>
              <li class="li1">AIRBAG KONTROL PAKETİ</li>
              <li class="li1">CONTA KA&Ccedil;AK TESTİ</li>
              <li class="li1">Termal Kamera(Emar-MR) Paketi</li>
              <li class="li1">
                Termal Kamera(Emar-MR) Kontroll&uuml; Kaporta/Boya Paketi
              </li>
              <li class="li1">ExpertAssist Garanti Paketi</li>
            </ul>
            <p class="p1">
              <strong>&Ccedil;ankaya, ANKARA Oto Eksper İletişim</strong>
            </p>
            <p class="p3">
              <span class="s3">
                <strong>Tel:</strong>{" "}
                <a href="tel:+900542204422050%2060">
                  <span class="s1">+90 542 442 50 60</span>
                </a>
              </span>
            </p>
            <p class="p3">
              <span class="s3">
                <strong>Adres:</strong>{" "}
                <a href="https://maps.app.goo.gl/5q6f2hiHhM5cCXFYA">
                  <span class="s1">
                    Cevizlidere, Mevlana Bulvarı, Balgat, G&ouml;kkuşağı Cd.
                    Yıldırım Kule No: 221/133, 06520 &Ccedil;ankaya/Ankara
                  </span>
                </a>
              </span>
            </p>
            <p className="seotext-title">Oto Ekspertiz Nedir?</p>
            <p className="seotext-description">
              Oto ekspertiz; bir otomobilin, konusunda uzman personeller
              tarafından teknik cihazlar yardımıyla A’dan Z’ye incelenmesi ve
              tüm kontrollerin sonucunda aracın genel durumuna dair rapor
              oluşturulmasıdır. Ekspertiz raporu (link:{" "}
              <a href="https://otorapor.com/ornek-raporlar2">
                https://otorapor.com/ornek-raporlar2
              </a>
              ), aracın teknik incelemelerinin yanı sıra Otorapor ekspertiz
              uzmanlarının görüşlerini, değerlendirmelerini ve aracın masraf
              çıkartabilecek parçaları gibi yorumları da içermektedir. Tam
              kapsamlı bir ekspertizde{!showMore ? "..." : " "}
              <span class="seotext-open">
                kaporta/boya, motor, mekanik, fren/süspansiyon, dinamometre, iç
                ve dış kondisyon, OBD/beyin testleri ve aracın borç/ceza
                sorgulamaları gerçekleştirilmektedir. Hemen randevu(link:{" "}
                <a href="https://otorapor.com/Arac/Randevu">
                  https://otorapor.com/Arac/Randevu
                </a>
                ) alarak kendi aracınızın veya satın alacağınız aracın durumu
                hakkında detaylı rapora sahip olun.
                <p>
                  <strong>Neden Oto Ekspertiz Yaptırılmalı?</strong>
                  <br />
                  Otomobil satın almadan önce mutlaka ekspertiz yaptırarak
                  yatırımınızı garanti altına almalısınız. Satın alacağınız
                  aracı gerçek değerinde almak, aracın performansını bilmek ve
                  satarken sürprizle karşılaşmamak için Otoraporlamalısınız.
                  Ekspertiz yaptırmadan satın alacağınız aracın; kilometresinin
                  düşürülmüş, kayıt dışı tamir işlemi görmüş, çeşitli
                  hilelerde(link:{" "}
                  <a href="https://otorapor.com/ikinci-elde-dolandiricilik-hikayeleri">
                    https://otorapor.com/ikinci-elde-dolandiricilik-hikayeleri
                  </a>
                  ) kullanılmış ve bilmediğiniz kusurlarının örtülmüş olma
                  ihtimali oldukça yüksektir.
                </p>
                <p>
                  <strong>Kurumsal Oto Ekspertiz</strong>
                  <br />
                  Türkiye’de gelişmekte olan ekspertiz sektörünün en önemli
                  kriterlerine Otorapor ile sahip olabilirsiniz. Tüm
                  operasyonunu tarafsızlık ve bağımsızlık ilkesiyle
                  gerçekleştiren Otorapor, ikinci el otomobil ticaretinin
                  güvenle gerçekleşmesini sağlamaktadır. Hem ekspertiz öncesinde
                  hem de ekspertiz sonrasında sunduğu hizmetler ve çözümlerle
                  müşterilerine sıra dışı bir deneyim sunmaktadır.
                </p>
                <p>
                  <strong>501 Nokta Ekspertiz</strong>
                  <br />
                  Aracınızın ekspertizi, Otorapor tarafından tescillenmiş 501
                  noktanın kontrolleri yapılarak gerçekleştirilir. Tüm bu
                  noktalara ait detaylı raporlama yapılır. Tüm müşterilerimize
                  hitap eden, farklı kontrol noktalarına sahip ekspertiz
                  paketleri (link:{" "}
                  <a href="https://otorapor.com/coklu-paketler2">
                    https://otorapor.com/coklu-paketler2
                  </a>
                  ) bulunmaktadır. Aracınızın markasına, modeline ve
                  taleplerinize göre paketlerden birisini tercih ederek
                  ekspertiz işlemlerine başlayabilirsiniz.
                </p>
                <p>
                  <strong>Otorapor ile TSE Belgeli Ekspertiz </strong>
                  <br />
                  TSE tarafından istenen tüm şartları karşılayan ekspertiz
                  bayileri, hizmet yeterlilik belgesi ile sertifikalandırılır.
                  Bu sertifika sayesinde ekspertiz raporu, TSE hizmet
                  standartları çerçevesinde oluşturulur.
                </p>
                <p>
                  <strong>Otorapor ile Garantili Ekspertiz</strong>
                  <br />
                  Otorapor, garanti şartlarını (link:{" "}
                  <a href="https://otorapor.com/2--el-otomobil-garantisi">
                    https://otorapor.com/2--el-otomobil-garantisi
                  </a>
                  ) sağlaması durumunda (yaş,km,ağır hasarsızlık,kg vb.)
                  ekspertizi yapılan araca kaporta/boya ve motor/mekanik garanti
                  poliçesi oluşturmaktadır. Garantinin süresi içerisinde
                  aracınızı garanti altında kullanma imkanına sahip olacaksınız.
                </p>
                <p>
                  <strong>Oto Ekspertiz Yaptırmak Zorunlu mu?</strong>
                  <br />
                  2.El Motorlu Kara Taşıtlarının Ticaretine İlişkin Yönetmelik’e
                  (
                  <a href="https://otorapor.com/ikinciel-yasasi">
                    https://otorapor.com/ikinciel-yasasi
                  </a>
                  ) göre; ikinci el otomobil veya arazi taşıtı satışı yapan
                  işletme tarafından, satışın yapıldığı tarihten üç gün içinde
                  yapılmış olan ekspertiz raporu şartı aranmaktadır. Bu
                  zorunluluk şahsi alım/satımlar için henüz zorunlu hale
                  getirilmemiştir.
                </p>
                <p>
                  <strong>Otorapor Akademi </strong>
                  <br />
                  Otorapor, kendi bünyesinde yetiştirdiği ekspertiz uzmanlarını
                  görevlendirmektedir. Otorapor Akademi bünyesinde eğitimini
                  başarıyla tamamlayan adaylar, eğitimin ardından sertifikalı
                  oto ekspertiz uzmanı olarak görevlendirilmektedir.
                </p>
                <p>
                  <strong>Otorapor Bayisi Olmak</strong>
                  <br />
                  Otorapor, denenmiş ve kanıtlanmış başarılarıyla rakiplerinden
                  her zaman bir adım önde yer almaktadır. Bu başarılara ortak
                  olmak için başvuruda(
                  <a href="https://otorapor.com/bayilik-franchise-basvurusu">
                    https://otorapor.com/bayilik-franchise-basvurusu
                  </a>
                  ) bulunabilirsiniz. Otorapor bayilik şartlarını (
                  <a href="https://otorapor.com/bayilik-franchise-sartlari">
                    https://otorapor.com/bayilik-franchise-sartlari
                  </a>
                  ) karşılayan adayların değerlendirme süreci tamamlandıktan
                  sonra bayi açılış süreci başlamaktadır. Ayrıca, farklı bir
                  marka çatısı altında bulunan ekspertiz firmalarının Otorapor’a
                  dönüşüm (
                  <a href="https://otorapor.com/Form/Index?id=18">
                    https://otorapor.com/Form/Index?id=18
                  </a>
                  ) başvuruları da değerlendirilmektedir.
                </p>
                <p>
                  <strong>
                    Otorapor Mobil ile Ekspertiz Raporunuz Cebinizde
                  </strong>
                  <br />
                  Otorapor’un güçlü bilişim altyapısının bir ürünü olan Otorapor
                  Mobil(
                  <a href="https://otorapor.com/otorapor-mobil">
                    https://otorapor.com/otorapor-mobil
                  </a>
                  ) uygulaması ile ekspertiz raporunu istediğiniz her an
                  yanınızda taşıyabilme imkanına sahipsiniz. QR kod(
                  <a href="https://www.youtube.com/watch?v=HCrSGecjJ8c&amp;t=262s">
                    https://www.youtube.com/watch?v=HCrSGecjJ8c&amp;t=262s
                  </a>
                  ) uygulamasıyla ekspertiz raporunuzu dijital olarak mobil
                  cihazlarınıza kaydedebilirsiniz.
                </p>
                <p>
                  <strong>Eski Ekspertiz Raporunuza Erişin</strong>
                  <br />
                  Rapor sorgulama ekranından (
                  <a href="https://otorapor.com/Arac/Rapor">
                    https://otorapor.com/Arac/Rapor
                  </a>
                  ) eski ekspertiz raporunuza ulaşabilir, satın almayı
                  düşündüğünüz araç daha önce otoraporlandıysa bu aracın eski
                  ekspertiz raporunu sorgulayabilirsiniz. Sorgulama sonucunda
                  aracın raporunu dijital olarak saklayabilirsiniz.
                </p>
                <p>
                  <strong>Otorapor Youtube Kanalı ile Otomobil Gündemi</strong>
                  <br />
                  Birbirinden farklı içeriklerin sunulduğu Otorapor youtube
                  kanalında (
                  <a href="https://www.youtube.com/user/otorapor">
                    https://www.youtube.com/user/otorapor
                  </a>
                  ), otomobil gündemine dair gelişmeleri takip edebilir ve
                  otomobil hakkında sıra dışı bilgilere sahip olabilirsiniz. Her
                  hafta paylaşılan eğitici ve bilgilendirici videoları
                  kaçırmamak için youtube kanalımızı ziyaret etmeyi ve abone
                  olmayı unutmayın.
                </p>
                <p>
                  <i>Göz atın</i>
                  <br />
                </p>
                <p>
                  Elektikli araçta bitcoin madenciliği yapılır mı? (
                  <a href="https://www.youtube.com/watch?v=vBkIxD9TwRw&amp;t=102s">
                    https://www.youtube.com/watch?v=vBkIxD9TwRw&amp;t=102s
                  </a>
                  )
                </p>
                <p>
                  Otomobil Sektörüne Balyoz: Çip Krizi (
                  <a href="https://www.youtube.com/watch?v=beiVwvZTAo0&amp;t=1388s">
                    https://www.youtube.com/watch?v=beiVwvZTAo0&amp;t=1388s
                  </a>
                  )
                </p>
              </span>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3061.6154195043887!2d32.81074847640737!3d39.88285158795934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d345e58296885f%3A0x729f5ca5d62e99e1!2sOtorapor%20Ankara%20%C3%87ankaya%20Balgat%20Oto%20Ekspertiz!5e0!3m2!1str!2str!4v1712218402410!5m2!1str!2str" style={{border: 0,width:"100%",height:"40vh"}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

            </p>
          </div>
       
        </div>
      </div>
    </>
  );
}

export default Expertise;
