import React from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { FaPhoneSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

const MainIcons = () => {
  return (
    <div
      className="d-grid m-3 justify-content-center align-items-center main-icons gap-2"
    
    >
  <a href="https://api.whatsapp.com/send/?phone=905424425060"><IoLogoWhatsapp style={{color:"#25d366"}} /></a>    
   <a href="https://maps.app.goo.gl/5q6f2hiHhM5cCXFYA"><MdLocationOn  style={{color:"#d21e2b"}}/></a>   
    <a href="tel:+905424425060"> <FaPhoneSquare style={{color:"#9f9f9f"}} /></a> 
    <a  href="https://www.instagram.com/otoraporpremiumankarabalgat/"  style={{color:"pink"}} ><FaInstagram />
</a>
<a  href="https://www.facebook.com/otoraporbalgat/"><FaFacebook />
</a>
    </div>
  );
};

export default MainIcons;
