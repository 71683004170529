import React from 'react';
import { CgChevronDown, CgChevronLeft } from 'react-icons/cg';

const menuData = [
    {
        title: 'Anasayfa',
        path: '/',
        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

      
    },

  

    {
        title: 'Hakkımızda',
        path: "",

        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        subNav: [
            {
                title: 'Biz Kimiz?',
                path: '/biz-kimiz',
                cName: 'sub-nav',
            },
            {
                title: 'Ne Yapıyoruz?',
                path: '/ne-yapiyoruz',
                cName: 'sub-nav',
            },
            {
                title: 'Kalite Belgelerimiz',
                path: '/kalite-belgelerimiz',
                cName: 'sub-nav',
            },
            {
                title: 'Referanslarımız',
                path: '/referanslarimiz',
                cName: 'sub-nav',
            },
            {
                title: 'Basında Biz',
                path: '/basinda-biz',
                cName: 'sub-nav',
            },
            {
                title: 'Otorapor TV',
                path: '/otorapor-tv',
                cName: 'sub-nav',
            },
            {
                title: 'Otorapor Mobil',
                path: '/otorapor-mobil',
                cName: 'sub-nav',
            },
        ],
    },
    {
        title: 'Fiyatlar',
        path: 'https://otorapor.com/Oto-Ekspertiz-Fiyatlari',
    },

    {
        title: 'Rapor Sorgulama',
        path: '',
        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        subNav: [
            {
                title: 'Rapor Sorgulama',
                path: 'https://otorapor.com/Arac/Rapor',
                cName: 'sub-nav',
            },
            {
                title: 'Hasar Sorgulama',
                path: 'https://otorapor.com/Arac/Sorgular',
                cName: 'sub-nav',
            },
            {
                title: 'Kilometre Sorgulama',
                path: 'https://otorapor.com/Arac/Sorgular',
                cName: 'sub-nav',
            },
          
        ],
   
    },
    {
        title: 'Faydalı Bilgiler',
        path: '',
        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        subNav: [
            {
                title: 'Kampanyalar',
                path: 'https://otorapor.com/guncel-kampanyalar',
                cName: 'sub-nav',
            },
            {
                title: 'Haberler & Blog',
                path: 'https://otorapor.com/haberler-duyurular',
                cName: 'sub-nav',
            },
            {
                title: 'Kilometre Hesapla',
                path: 'https://otorapor.com/Arac/KMHesapla',
                cName: 'sub-nav',
            },
            {
                title: 'Aracım Ne Kadar?',
                path: 'https://otorapor.com/Arac/FiyatBul',
                cName: 'sub-nav',
            },
            {
                title: 'Aracın Piyasası Var Mı?',
                path: 'https://otorapor.com/Arac/AracPiyasasi',
                cName: 'sub-nav',
            },
            {
                title: 'Değer Kaybı',
                path: 'https://otorapor.com/Arac/KazaliArac',
                cName: 'sub-nav',
            },
            {
                title: 'Araba Önerici',
                path: 'https://otorapor.com/Arac/AracBul',
                cName: 'sub-nav',
            },
            {
                title: 'Paket Önerici',
                path: 'https://otorapor.com/Arac/PaketOnerici',
                cName: 'sub-nav',
            },
            {
                title: 'Araç Fiyatlandırma',
                path: 'https://otorapor.com/arac-fiyatlandirma',
                cName: 'sub-nav',
            },
            {
                title: 'Araçların Kronik Arızaları',
                path: 'https://otorapor.com/araclarin-kronik-arizalari2',
                cName: 'sub-nav',
            },
            {
                title: 'Araba Danışın',
                path: 'https://otorapor.com/arabanizi-bulalim',
                cName: 'sub-nav',
            },
            {
                title: 'Ustalarımıza Sorun',
                path: 'https://otorapor.com/Arac/SoruCevap',
                cName: 'sub-nav',
            },
            {
                title: 'Oto Ekspertiz ve Alım Satım Kitabı',
                path: 'https://otorapor.com/otoekspertiz-alim-satim-kitabi',
                cName: 'sub-nav',
            },
            {
                title: 'Oto Mr Sistemi Ankara',
                path: '/oto-mr-sistemi-ankara',
                cName: 'sub-nav',
            },
        ],
   
    },

    {
        title: 'İletişim',
        path: '/bize-ulasin',
    },
];

export default menuData;