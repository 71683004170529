import React from "react";
import Banner from "../../banner";
import Brand from "../../brand";
import {Helmet} from "react-helmet";

function OtoRaporMobil() {
  return (
    <>  <Helmet>
    <title>Otorapor TV - Otorapor Balgat</title>
    <meta name="description" content="Otorapor Balgat olarak, otomobil dünyasıyla ilgili en son gelişmeleri ve bilgileri müşterilerimize sunmayı amaçlıyoruz."/>
    <link
      rel="canonical"
      href="https://www.otoraporbalgat.com/otorapor-tv"
    />
     <link rel="icon" href="/favicon.ico" />
  </Helmet>
  <h1 style={{display:"none"}}>Otorapor TV - Otorapor Balgat</h1>
      <Banner url={"/otorapor-tv"} text={"Otorapor TV"} />
      <div className="section-block">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div className="pl-30-md">
                <div className="section-heading">
                  <h4>Otorapor TV</h4>
                </div>
                <div className="section-heading-line-left" />
                <div className="text-content-big mt-20">
                  <h3>Otorapor Youtube Kanalı Yayında</h3>
                  <p>
                    kinci el araç alırken danışabileceğiniz video bilgi paylaşım
                    kanalı OTORAPOR TV yayında.
                  </p>
                  <p>
                    OTORAPOR TV, Türkiye'de 2007'den beri ikinci el araçların
                    kaza, arıza durumlarını test ve muayene edip detaylı oto
                    ekspertiz raporu düzenleyen, ilk profesyonel, tarafsız,
                    bağımsız ve tam donanımlı oto expertiz kuruluşu OTORAPOR'un
                    YouTube kanalıdır. YouTube kanalımızda bilgi içeriklerinin
                    yanı sıra kampanyalar, röportajlar, bilgi videoları,
                    yurtdışı otomobil müzeleri gezileri, forumlar, eğlenceli
                    videolar yayınlanmaktadır. Abone olmayı unutmayın.
                    Videolardan ilk sizin haberiniz olsun.
                  </p>
                </div>
                <div className="row mt-40 px-2 otorapor-iframe">
                  <div className="col-lg-6  col-md-6 col-sm-12 d-flex justify-content-center my-2">
                    <iframe

                      src={
                        "https://www.youtube.com/embed/F4Z6aO98p4g?si=ZHhDWypYp2kYpuCV"
                      }
                      title="YouTube video player"
                     
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen=""
                    ></iframe>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center my-2">
                    <iframe
                      src={
                        "https://www.youtube.com/embed/CBd6GB2QMYg?si=G7OlH7LbAeN6q3-g"
                      }
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen=""
                    ></iframe>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center my-2">
                    <iframe
                      src={
                        "https://www.youtube.com/embed/4KJLJalOh7g?si=gUxAbibcDjO-NS91"
                      }
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen=""
                    ></iframe>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center my-2">
                    <iframe
                      src={
                        "https://www.youtube.com/embed/h3IHV5_ZLmM?si=R_saXZvRpc8385TO"
                      }
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen=""
                    ></iframe>
                  </div>
                </div>
                <div className="text-center my-5">
                  <a
                    class="primary-button button-sm mb-15-m"
                    href="https://www.youtube.com/channel/UCCM_X6hZJMvGFlhrF53RSLg"
                  >
                    İZLEMEK VE ABONE OLMAK İÇİN TIKLAYINIZ
                  </a>
                </div>
              </div>
              <div class="blog-post-share">
                <div class="product-share mt-20">
                  <span>Paylaş: </span>
                  <a
                    title="Facebook'ta Paylaş"
                    href="https://www.facebook.com/sharer/sharer.php?u=http://otorapor.com/otorapor-tv&amp;p[title]=Otorapor TV"
                    target="_blank"
                  >
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a
                    title="Twitter'da Paylaş"
                    href="https://twitter.com/intent/tweet?text=Otorapor TV&amp;url=http://otorapor.com/otorapor-tv"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path>
                    </svg>
                  </a>
                  <a
                    title="LinkedIn'de Paylaş"
                    href="http://linkedin.com/shareArticle?mini=true&amp;url=http://otorapor.com/otorapor-tv&amp;title=Otorapor TV"
                    target="_blank"
                  >
                    <i class="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                  <a
                    title="Tumblr'da Paylaş"
                    href="http://www.tumblr.com/share/link?url=http://otorapor.com/otorapor-tv&amp;name=Otorapor TV&amp;description='"
                    target="_blank"
                  >
                    <i class="fa fa-tumblr" aria-hidden="true"></i>
                  </a>
                  <a
                    title="Pinterest'te Paylaş"
                    href="http://pinterest.com/pin/create/button/?url=http://otorapor.com/otorapor-tv&amp;description=Otorapor TV&amp;media='"
                    target="_blank"
                  >
                    <i class="fa fa-pinterest" aria-hidden="true"></i>
                  </a>
                  <a
                    title="Whatsapp Paylaş"
                    href="whatsapp://send?text=http://otorapor.com/otorapor-tv&amp;t=Otorapor TV"
                    data-action="share/whatsapp/share"
                    target="blank"
                  >
                    <i class="fa fa-whatsapp" aria-hidden="true">
                      {" "}
                      Whatsapp
                    </i>
                  </a>
                </div>
              </div>
            </div>
            <div class="blog-list-right">
              <div class="blog-list-left-heading">
                <h4>Etiketler</h4>
              </div>
              <div class="mt-10">
                <a href="#" class="button-tag primary-button">
                  otorapor
                </a>
                <a href="#" class="button-tag primary-button">
                  {" "}
                  otorapor tv
                </a>
                <a href="#" class="button-tag primary-button">
                  {" "}
                  youtube
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <Brand />
          </div>
        </div>
      </div>
    </>
  );
}

export default OtoRaporMobil;
