import React from 'react';
import { FaTh } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { Link,NavLink } from 'react-router-dom';
import "./index.css"

function MainMenu({ hanldeOffset }) {
    return (
        <>
            <div className="main-menu-area p-0 m-0">
                <div className="row align-center">
                    <div className="col-6 col-md-8 col-lg-11 header-main-menu">
                        <div className="main-menu">
                            <ul>
                          
                                <li>
                                    <a>HAKKIMIZDA</a>
                                    <ul className="sub-menu">
                                        <li>
                                            <NavLink activeClassName="active" to="/biz-kimiz">Biz Kimiz?</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/ne-yapiyoruz">Ne Yapıyoruz?</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/kalite-belgelerimiz">Kalite Belgelerimiz</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/referanslarimiz">Referanslarımız</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/basinda-biz">Basında Biz</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/otorapor-tv">Otorapor TV</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/otorapor-mobil">Otorapor Mobil</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" target='_blank' to="https://otorapor.com/Oto-Ekspertiz-Fiyatlari">FİYATLAR</NavLink>
                                </li>
                                <li>
                                    <a>RAPOR SORGULAMA</a>
                                
                                    <ul className="sub-menu">
                                        <li>
                                            <NavLink activeClassName="active" target='_blank' to="https://otorapor.com/Arac/Rapor">Rapor Sorgulama</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank' to="https://otorapor.com/Arac/Sorgular">Hasar Sorgulama</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank' to="https://otorapor.com/Arac/Sorgular">Kilometre Sorgulama</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a>FAYDALI BİLGİLER</a>
                                    <ul className="sub-menu">
                                        <li>
                                            <NavLink activeClassName="active" target='_blank' to="https://otorapor.com/guncel-kampanyalar">Kampanyalar</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank' to="https://otorapor.com/haberler-duyurular">Haberler & Blog</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank' to="https://otorapor.com/Arac/KMHesapla">Kilometre Hesapla</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank'  to="https://otorapor.com/Arac/FiyatBul">Aracım Ne Kadar?</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank'  to="https://otorapor.com/Arac/AracPiyasasi">Aracın Piyasası Var Mı?</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank' to="https://otorapor.com/Arac/KazaliArac">Değer Kaybı</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank'  to="https://otorapor.com/Arac/AracBul">Araba Önerici</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank'  to="https://otorapor.com/Arac/PaketOnerici">Paket Önerici</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank'  to="https://otorapor.com/arac-fiyatlandirma">Araç Fiyatlandırma</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank'  to="https://otorapor.com/araclarin-kronik-arizalari2">Araçların Kronik Arızaları</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank'  to="https://otorapor.com/arabanizi-bulalim">Araba Danışın</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank'  to="https://otorapor.com/Arac/SoruCevap">Ustalarımıza Sorun</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank'  to="https://otorapor.com/otoekspertiz-alim-satim-kitabi">Oto Ekspertiz ve Alım Satım Kitabı</NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" target='_blank'  to="/oto-mr-sistemi-ankara">Oto Mr Sistemi Ankara</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink  activeClassName="active" to="/bize-ulasin">İLETİŞİM</NavLink>
                                </li>
                                <li className='last-li'>
                                <button type="button" onClick={hanldeOffset} className="side-menu-toggle">
                            <FaTh />
                        </button>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
              
                </div>
            </div>
        </>
    );
}

export default MainMenu;
