import React from "react";
import './index.css';

function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-6 col-12">
                        <h3>Hakkımızda</h3>
                        <div className="mt-25">
                            <p className="mt-25">OTORAPOR</p>
                            <div className="textwidget">
                                <p>Bağımsızlığı uluslararası kuruluşlar tarafından onaylanmış, Türkiye'nin ilk kalite sertifikalı oto ekspertiz kuruluşu.</p>

                            </div>
                            <div class="footer-social-icons mt-25">
                                <ul>
                                    <li><a href="http://www.youtube.com/user/otorapor" target="_blank" title="Youtube"><i class="fa fa-youtube" aria-hidden="true"></i></a></li>
                                    <li><a href="http://instagram.com/otorapor" target="_blank" title="Instagram"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                    <li><a href="https://twitter.com/otorapor" target="_blank" title="Twitter" style={{ padding: "8px 11px 11px 10px" }}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" style={{ fill: "#fff" }}><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg></a></li>
                                    <li><a href="https://www.facebook.com/otoraporofficial" target="_blank" title="Facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/18020513" target="_blank" title="Linkedin"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li><a href="https://open.spotify.com/user/6oe7hp6ua0jf9e7h830pyhmc4" target="_blank" title="Spotify"><i class="fa fa-spotify" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.tiktok.com/@otoraportv" target="_blank" title="Tiktok" style={{ padding: "8px 11px 11px 10px" }}><img src="/assets/images/social/footer-tiktok.png" loading="lazy" alt="Tiktok" width="14" height="14" /></a></li>
                                    <li><a href="https://www.threads.net/@otorapor" target="_blank" title="Threads" style={{ padding: "8px 11px 11px 10px" }}><img src="/assets/images/social/footer-threads.png" loading="lazy" alt="Threads" width="14" height="14" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-12">
                        <h3></h3>
                        <ul class="footer-list" >
                            <li><a target="_blank" href="https://otorapor.com/kampanyalar2">&gt; KAMPANYALAR</a></li>
                            <li><a href="/otorapor-mobil">&gt; Otorapor Mobil</a></li>
                            <li><a href="/biz-kimiz">&gt; BİZ KİMİZ?</a></li>
                            <li><a  target="_blank"  href="https://otorapor.com/Form/Index?id=9">&gt; İNSAN KAYNAKLARI</a></li>
                            <li><a  target="_blank"  href="https://otorapor.com/gizlilik-ve-kullanim">&gt; GİZLİLİK ve KULLANIM</a>
                            </li><li><a  target="_blank"  href="https://otorapor.com/2--el-otomobil-garantisi">&gt; GARANTİ ŞARTLARI</a></li>
                            <li><a  target="_blank"  href="https://otorapor.com/etik-degerlerimiz" target="_self">&gt; Etik Değerlerimiz</a></li>
                            <li><a href="/sikca-sorulan-sorular">&gt; Sıkça Sorulan Sorular</a></li>
                            <li><a  target="_blank"  href="https://otorapor.com/politikalarimiz">&gt; Politikalarımız</a></li>
                        </ul>
                    </div>
                    <div className="col-md-3 col-sm-6 col-12">
                        <h3></h3>

                        <ul class="footer-list">
                            <li><a target="_blank"  href="https://otorapor.com/ornek-raporlar2">&gt; Örnek Oto Ekspertiz Raporu</a></li>
                            <li><a target="_blank"  href="https://otorapor.com/Arac/FiyatBul" target="_blank">&gt; Aracımın Değeri Nedir?</a></li>
                            <li><a target="_blank"  href="https://otorapor.com/Form/Index?id=13" target="_blank">&gt; Değer Kaybı Başvuru</a></li>
                            <li><a target="_blank"  href="https://otorapor.com/Arac/SoruSor">&gt; Ustalarımıza Sorun</a></li>
                            <li><a target="_blank"  href="https://otorapor.com/Form/Index?id=14">&gt; Kasko / Sigorta Teklifi</a></li>
                            <li><a target="_blank"  href="https://otorapor.com/oto-ekspertiz-uzmanligi" target="_blank">&gt; Oto Ekspertiz Sertifikası</a></li>
                            <li><a target="_blank"  href="https://otorapor.com/kisisel-verileri-koruma">&gt; Kişisel Verileri Koruma</a></li>
                            <li><a target="_blank"  href="https://otorapor.com/gizlilik-guvenlik-ve-iptal-iade-kosullari" target="_blank">&gt; Gizlilik ve Güvenlik</a></li>
                            <li><a target="_blank"  href="https://otorapor.com/gizlilik-guvenlik-ve-iptal-iade-kosullari" target="_blank">&gt; İptal ve İade Koşulları</a></li>
                            <li><a target="_blank"  href="https://otorapor.com/hizmet-sozlesmesi" target="_blank">&gt; Hizmet Sözleşmesi</a></li>
                            <li><a target="_blank"  href="https://otorapor.com/arac/randevuiptal">&gt; Randevu İptali</a></li>
                        </ul>
                        <div className="d-flex mt-10">
                            <a  target="_blank"  href="https://otorapor.com/arac/sikayet" class="primary-button2 button-md mt-15-xs">İstek, Öneri ve Şikayet</a>
                        </div></div>
                    <div className="col-md-3 col-sm-6 col-12">
                        <h3></h3>
                        <div class="footer-tags mt-25">
                            <a href="#">oto ekspertiz</a>
                            <a href="#">otorapor</a>
                            <a href="#">araç ekspertiz</a>
                            <a href="#">ekspertiz</a>
                            <a href="#">kolay ekspertiz</a>
                            <a href="#">profesyonel ekspertiz</a>
                            <a href="#">kurumsal ekspertiz</a>
                            <a href="#">en iyi ekspertiz</a>
                            <a href="#">en iyi oto ekspertiz</a>
                            <a href="#">yetkili oto ekspertiz</a>
                            <a href="#">tse hyb ekspertiz</a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <a href="https://otoekspertizdernegi.com" title="OTOEKSDER" target="_blank">
                            <img src="/assets/images/otoexderdernegiuyesi.webp" className="img-fluid" loading="lazy" alt="OTOEKSDER" /></a>
                        <img src="/assets/images/otorapor_kalite_logolari.png" className="img-fluid" loading="lazy" alt="Güven Logolarımız" />

                    </div>
                    <div class="col-md-6 text-align-right">
                        <a href="https://otolira.com" title="Otolira" target="_blank"><img src="/assets/images/otolira_logo_son.png" loading="lazy" alt="Otolira" /></a>
                    </div>
                </div>
                <div class="footer-bar d-flex justify-content-end">
            <p>
                Tüm hakları saklıdır 2023 ©
                <a href="https://osicrew.com" target="_blank" title="osiCrew" rel="noopener noreferrer" class="primary-color">
                <span href="https://osicrew.com" target="_blank" title="osiCrew" rel="noopener noreferrer" class="primary-color"> made by osiCrew</span></a>
            </p>
        </div>

            </div>

        </footer>

    );
}

export default Footer;