
import React from 'react';
import './index.css';
import Banner from "../../banner";
import {Helmet} from "react-helmet";

const ContentItem = ({ type, content }) => {
  if (type === 'iframe') {
    return (
      <div className="col-lg-4 my-2 col-md-6 col-sm-5 " style={{ height: "300px" }}>
        <iframe src={content} style={{ height: "100%" }} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
      </div>
    );
  } else if (type === 'image') {
    return (
      <div className="col-lg-4  my-2 col-md-6 col-sm-5 " style={{ height: "300px" }}>
        <img src={content} style={{ height: "100%" }} alt="image content" className="img-fluid" />
      </div>
    );
  } else {
    return null;
  }
};

const App = () => {
  const items = [
    { type: 'iframe', content: 'https://www.youtube.com/embed/wT-j3ikMN3U' },
    { type: 'iframe', content: 'https://www.youtube.com/embed/5gMRojcvkEo' },
    { type: 'image', content: '/assets/images/s_autoshow_otorapor.jpg' },
    { type: 'image', content: '/assets/images/s_IMG_7387.jpeg' },
    { type: 'image', content: '/assets/images/s_SCAN_20160309_144632352.jpg' },
    { type: 'iframe', content: 'https://www.youtube.com/embed/ZWYkJHUmR5s' },
    { type: 'image', content: '/assets/images/s_1.jpg' },
    { type: 'image', content: '/assets/images/s_2.jpg' },

  ];

  return (
    <>
      <Helmet>
        <title>Basında Biz - Otorapor Balgat</title>
        <meta name="description" content="Otorapor Balgat, otomobil endüstrisinde güvenilirliği ve uzmanlığıyla tanınan bir firma olarak basında da sıkça yer almaktadır."/>
        <link
          rel="canonical"
          href="https://www.otoraporbalgat.com/basinda-biz"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <h1 style={{display:"none"}}>Basında Biz Otorapor</h1>
      <Banner url={"/basinda-biz"} text={"BASINDA BİZ"} />
      <div className="section-block">
        <div className="container">
          <div class="section-heading">
            <h4>BASINDA BİZ</h4>
            <div class="section-heading-line-left"> </div>

          </div>
          <div className="row mt-50 d-flex justify-content-around">
            {items.map((item, index) => (
              <ContentItem key={index} type={item.type} content={item.content} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
