import React from "react";
import Banner from "../../banner";
import {Helmet} from "react-helmet";

import './index.css';

function WhoWeAre() {
  return (
    <>
      <Helmet>
        <title>Ne Yapıyoruz - Otorapor Balgat</title>
        <meta name="description" content="Otorapor Balgat olarak, müşterilerimize güvenilirlik ve güvenlik konularında eksiksiz bilgi sunmayı amaçlıyoruz."/>
        <link
          rel="canonical"
          href="https://www.otoraporbalgat.com/ne-yapiyoruz"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <h1 style={{display:"none"}}>Ne Yapıyoruz Otorapor</h1>
      <Banner url={"/ne-yapiyoruz"} text={"Ne Yapıyoruz ?"} />
      <div className="section-block">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12 mt-60">
              <div class="section-heading">
                <h5> NE YAPIYORUZ?</h5>
                <div class="section-heading-line-left"></div>
              </div>
            </div>

          </div>
          <div class="row d-flex justify-content-center">

           
            <div class="col-12  text-center " >

              <iframe width="560" height="315" src="https://www.youtube.com/embed/HREG0hvDDjk?si=820LsLv2GpKVSogF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
              <br />
            </div>
          </div>
          <div class="text-content-big mt-20">

            <p>
              OTORAPOR, alıcı ve satıcılara bir araya gelerek ekspertiz yaptırabilecekleri, anlaşabilecekleri, satın alacakları, araçlarını güvenle nakit satabilecekleri ya da satacakları araçla ilgili her türlü konuyu danışabilecekleri, fikir alabilecekleri ve pazarlık yapabilecekleri rahat bir ortam sunuyor. OTORAPOR’da her marka ikinci el otomobile Bağımsız Oto Ekspertiz Raporu düzenleniyor. Bunun için OTORAPOR'a  satın almak istediğiniz aracın bilgilerini verebilir ve tercih ettiğiniz ekspertiz / raporlama paketini seçip randevu aldıktan sonra aracı size en yakın OTORAPOR servisine götürebilirsiniz.


              Araç, tam donanımlı OTORAPOR servislerinde profesyonel test cihazlarıyla (dinamometre, fren test, yanal kayma, süspansiyon test, dijital far test, akü test, ön boşluk test, lastik derinlik ölçer, boya kalınlık ölçer, gürültü test, OBD diagnoz arıza tespit) tepeden tırnağa 501 NOKTA ekspertizinden geçirilir ve raporu size sunulur.


              Tüm OTORAPOR servisleri, noterlerin, bankaların ve trafik müşavirliklerinin mevcut olduğu bölgelerde bulunuyor. Dolayısıyla araç alım – satım işlemlerinizi hızlı ve rahat bir şekilde gerçekleştirebilirsiniz. Ayrıca araçlar ya da alım – satım prosedürleri hakkındaki sorularınızı da danışmanlarımıza sorabilir ve en güvenilir yanıtları alabilirsiniz.
            </p>
          </div>
          <div class="mt-35 align-content-center text-center" >
            <a target="_blank" href="https://otorapor.com/oto-ekspertiz-hizmetleri" class="primary-button button-sm mb-15-xs">Hizmetlerimiz</a>
          </div>

        </div>
      </div>


    </>

  );
}

export default WhoWeAre;