import React from "react";
import Banner from "../../banner";
import Brand from "../../brand";
import {Helmet} from "react-helmet";

function OtoRaporTv() {
    return (
        <>  <Helmet>
        <title>Otorapor Mobil - Otorapor Balgat</title>
        <meta name="description" content="Otorapor Balgat olarak, müşterilerimize en iyi hizmeti sunabilmek adına mobil uygulamamızı geliştirdik."/>
        <link
          rel="canonical"
          href="https://www.otoraporbalgat.com/otorapor-mobil"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <h1 style={{display:"none"}}>Otorapor Mobil - Otorapor </h1>
            <Banner url={"/otorapor-mobil"} text={"Otorapor Mobil"} />
            <div className="section-block">
                <div className="container">
                    <div className="row">
                   
                        <div className="col-md-12 col-sm-12 col-12">
                            <div className="pl-30-md">
                                <div className="section-heading">
                                    <h4>Otorapor Mobil</h4>
                                </div>
                                <div className="section-heading-line-left" />
                       
                                <div className="row  px-2" >
                                    <div className="col-12 d-flex justify-content-center">
                                        <img src="/assets/images/otorapormobil.jpeg" className="img-fluid mt-40"/>

                                    </div>
                                    <div className="text-content-big mt-5">
                                    <p>
                                    Otorapor mobil, ekspertiz işlemlerinizi kolayca yapmanızı sağlayan, her an ulaşabileceğiniz bir mobil uygulamadır. </p>
                                    <p>
                                    Birbirinden farklı özelliklerle sizlere sıra dışı bir deneyim sunan Otorapor mobil ile hemen tanışın ve kampanyalardan haberdar olun.                                    </p>
                          
                                </div>
                                   

                                </div>
                                <div className="text-center my-5"><a class="primary-button button-sm mb-15-m" href="https://www.youtube.com/channel/UCCM_X6hZJMvGFlhrF53RSLg">İZLEMEK VE ABONE OLMAK İÇİN TIKLAYINIZ</a></div>

                            </div>
                            <div class="blog-post-share">



                                <div class="product-share mt-20">
                                    <span>Paylaş: </span>
                                    <a title="Facebook'ta Paylaş" href="https://www.facebook.com/sharer/sharer.php?u=http://otorapor.com/otorapor-tv&amp;p[title]=Otorapor TV" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                    <a title="Twitter'da Paylaş" href="https://twitter.com/intent/tweet?text=Otorapor TV&amp;url=http://otorapor.com/otorapor-tv" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg></a>
                                    <a title="LinkedIn'de Paylaş" href="http://linkedin.com/shareArticle?mini=true&amp;url=http://otorapor.com/otorapor-tv&amp;title=Otorapor TV" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                    <a title="Tumblr'da Paylaş" href="http://www.tumblr.com/share/link?url=http://otorapor.com/otorapor-tv&amp;name=Otorapor TV&amp;description='" target="_blank"><i class="fa fa-tumblr" aria-hidden="true"></i></a>
                                    <a title="Pinterest'te Paylaş" href="http://pinterest.com/pin/create/button/?url=http://otorapor.com/otorapor-tv&amp;description=Otorapor TV&amp;media='" target="_blank"><i class="fa fa-pinterest" aria-hidden="true"></i></a>
                                    <a title="Whatsapp Paylaş" href="whatsapp://send?text=http://otorapor.com/otorapor-tv&amp;t=Otorapor TV" data-action="share/whatsapp/share" target="blank"><i class="fa fa-whatsapp" aria-hidden="true"> Whatsapp</i></a>
                                </div>
                            </div>
                        </div>
                        <div class="blog-list-right">
                            <div class="blog-list-left-heading">
                                <h4>Etiketler</h4>
                            </div>
                            <div class="mt-10">

                                <a href="#" class="button-tag primary-button">otorapor</a>
                                <a href="#" class="button-tag primary-button"> otorapor tv</a>
                                <a href="#" class="button-tag primary-button"> youtube</a>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <Brand />
                    </div>


                </div>
            </div>


        </>

    );
}

export default OtoRaporTv;

