import React from "react";
import './index.css';
import Banner from "../../banner";
import {Helmet} from "react-helmet";

function WhoWeAre() {
  return (
    <>
      <Helmet>
        <title>Hakkımızda - Otorapor Balgat</title>
        <meta name="description" content="Otorapor Balgat, otomobil sahiplerine güvenilir ve tarafsız oto ekspertiz hizmeti sunan öncü bir firmadır."/>
        <link
          rel="canonical"
          href="https://www.otoraporbalgat.com/biz-kimiz"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <h1 style={{display:"none"}} >Hakkımızda Otorapor</h1>
      <Banner url={"/biz-kimiz"} text={"Hakkımızda"} />
      <div className="section-block">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <iframe className="rounded-border shadow-primary" frameBorder={0} height="100%" style={{ width: '100%' }} src="https://www.youtube.com/embed/pWwerYKq-rw?rel=0&controls=0&showinfo=0" />
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <div className="pl-30-md">
                <div className="section-heading">
                  <h4>BİZ KİMİZ?</h4>
                </div>
                <div className="section-heading-line-left" />
                <div className="text-content-big mt-20">
                  <p>
                    OTORAPOR, teknik cihaz, ekipman ve robotlarla ikinci el araçların kaza/arıza durumlarını test ve muayene edip detaylı oto ekspertiz raporu düzenleyen, Türkiye’nin ilk profesyonel, tarafsız, bağımsız ve tam donanımlı oto ekspertiz kuruluşu ve ikinci el oto test check-up merkezidir. 2007 yılında İstanbul’da faaliyete başlayan OTORAPOR, bugün ülke çapına yayılmış bayi ağı ile birçok yerli ve ithal aracın alım – satım öncesi ekspertizini bağımsızlık ilkesiyle hızlı ve güvenilir bir şekilde gerçekleştirmektedir.

                  </p>
                </div>
                <div className="row mt-20">
                  <div className="col-md-6">
                    <ul className="primary-list">
                      <li><i className="fa fa-caret-right" aria-hidden="true" />Yaygın Şube Ağı</li>
                      <li><i className="fa fa-caret-right" aria-hidden="true" />Her Noktada Aynı Kalitede Kurumsal Hizmet</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="primary-list">
                      <li><i className="fa fa-caret-right" aria-hidden="true" />Bilimsel ve Teknik Verilere Dayalı Analiz</li>
                      <li><i className="fa fa-caret-right" aria-hidden="true" />Tecrübeli ve Eğitmen Usta Kadrosu</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-35">
                  <a href="/bize-ulasin" className="primary-button button-sm mb-15-xs">Bize Ulaşın</a>
                </div>
              </div>
            </div>
        
          </div>

          <div className="row mt-35">
          <div class="col-md-4 col-sm-4 col-12">
                <div class="feature-flex-square">
                    <div class="clearfix">
                        <div class="feature-flex-square-icon">
                            <i class="fas fa-desktop"></i>
                        </div>
                        <div class="feature-flex-square-content">
                            <h4><a href="#">Güvenilir ve Tarafsız Hizmet</a></h4>
                            <p>Otorapor araç alıcı ve satıcısına eşit mesafede kalarak araç alıcısının araç hakkında doğru bilgiye ulaşma hakkını korur.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-12">
                <div class="feature-flex-square">
                    <div class="clearfix">
                        <div class="feature-flex-square-icon">
                            <i class="fas fa-wallet"></i>
                        </div>
                        <div class="feature-flex-square-content">
                            <h4><a href="#">Yüksek Kalite Standartları</a></h4>
                            <p>Tüm Otorapor Şubeleri, Türk Standartları Enstitüsü’nün araç ekspertiz merkezleri standartlarını sağlamaktadır ve Kalite Yönetim Sistemi prensiplerini uygular.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-12">
                <div class="feature-flex-square">
                    <div class="clearfix">
                        <div class="feature-flex-square-icon">
                            <i class="fas fa-clock"></i>
                        </div>
                        <div class="feature-flex-square-content">
                            <h4><a href="#">OTORAPORLAMAK ve Raporların Saklanması</a></h4>
                            <p>‘’OTORAPORLAMA’’ kavramı, araç ile ilgili tüm verilerin hatasız olarak raporlanarak dijital ortamda minimum 5 yıl süreyle saklanmasıdır. Yaklaşık 1.2 milyon aracın verisi dijital ortamda saklanmaktadır ve bu sayı her geçen gün artmaktadır.</p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>


    </>

  );
}

export default WhoWeAre;