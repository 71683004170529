import React from 'react';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import {
    FaClock,
    FaDribbble,
    FaFacebookF,
    FaLinkedinIn,
    FaMap,
    FaPlus,
    FaTwitter,
    FaYoutube
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

function OffsetMenu({ className, handleOffset }) {
    return (
        <div className={className}>
            <span id="offset-menu-close-btn">
                <FaPlus onClick={handleOffset} />
            </span>
            <div className="offset-menu-wrapper text-white">
                <div className="offset-menu-header">
                    <div className="offset-menu-logo">
                        <Link to="/">
                            <img src="/assets/images/logo.png" alt="logo" />
                        </Link>
                    </div>
                </div>
                <div className="offset-menu-section">
                    <h3>Hakkımızda</h3>
                    <p>
                    Ankara Balgat şubesi olarak, ikinci el araçlarınız için tarafsız ve profesyonel ekspertiz hizmeti sunuyoruz. Güvenli alım-satım için bize güvenebilirsiniz.
                    </p>
                  
                </div>
                <div className="offset-menu-section">
                    <h3>Bize Ulaşın</h3>
                    <ul>
                        <li>
                            <span>
                                <FaMap />
                            </span>
                            <a target='_"blank' href='https://goo.gl/maps/Kv7tq9Zn5XA2H4jaA'>Balgat, Mevlana Bulvarı Yıldırım Kule No: 221/133 Cevizlidere Mahallesi, Gökkuşağı Cd., 06520 Çankaya/Ankara</a>
                        </li>
                        <li>
                            <span>
                                <AiOutlinePhone />
                            </span>
                            <a href='tel:05424425060'>0542 442 50 60</a>
                        </li>
                        <li>
                            <span>
                                <AiOutlineMail />
                            </span>
                            <a target='_blank' href='mailto:info@otoraporbalgat.com'>info@otoraporbalgat.com</a>
                        </li>
                        <li>
                            <span>
                                <FaClock />
                            </span>
                            Hafta içi: 09.00 - 18.00
                        </li>
                        <li>
                            <span>
                                <FaClock />
                            </span>
                            Cumartesi: 09.00 - 17.00
                        </li>
                        <li>
                            <span>
                                <FaClock />
                            </span>
                            Pazar: 09.00 - 17.00
                        </li>
                    </ul>
                </div>
                <div className="offset-menu-footer">
                    <div className="offset-menu-social-icon">
                        <a href="/">
                            <FaFacebookF />
                        </a>
                        <a href="/">
                            <FaTwitter />
                        </a>
                        <a href="/">
                            <FaLinkedinIn />
                        </a>
                        <a href="/">
                            <FaDribbble />
                        </a>
                        <a href="/">
                            <FaYoutube />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OffsetMenu;
