import Content from '../../../components/aboutUs/faq';
import Expertise from '../../../components/dashboard/expertise-description';

import Layout from '../../../components/layout';

function WhoWeAre() {
  return (
    <>
     <Layout>
    <Content/>
    <Expertise/>
    </Layout>

    </>
  );
}

export default WhoWeAre;
