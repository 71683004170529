import React from 'react';
import "./index.css"

function Topbar() {
    return (
        <div className="header-fixed">
        <div id="top-bar" class="hidden-sm-down">
        <div class="container">
            <div class="row">
                <div class="col-md-9 col-8 col-sm-8">
                    <div class="top-bar-info ">
                        <ul>
                            <li>TARAFSIZ, BAĞIMSIZ, PROFESYONEL</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3 col-4 col-sm-4">
                    <ul class="social-icons hidden-md-down">
                        <li><a href="http://instagram.com/otorapor"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/otorapor/"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                        <li><a href="http://www.youtube.com/user/otorapor"><i class="fa fa-youtube" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </div>
    );
}

export default Topbar;
