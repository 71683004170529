import Content from '../../../components/aboutUs/otoraporTv';
import Layout from '../../../components/layout';
import Expertise from '../../../components/dashboard/expertise-description';
function OtoraporTv() {
  return (
    <>
     <Layout>
    <Content/>
    <Expertise/>

    </Layout>

    </>
  );
}

export default OtoraporTv;
