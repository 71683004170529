import Content from '../../components/dashboard';

import Layout from '../../components/layout';

function Dashboard() {
  return (
    <>
     <Layout>
    <Content/>

    </Layout>

    </>
  );
}

export default Dashboard;
