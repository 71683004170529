import Content from '../../../components/aboutUs/qualityDocuments';
import Layout from '../../../components/layout';

function QualityDocument() {
    return (
        <>
            <Layout>
                <Content />
            </Layout>

        </>
    );
}

export default QualityDocument;
