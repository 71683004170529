import Content from '../../../components/aboutUs/news';
import Layout from '../../../components/layout';

function News() {
    return (
        <>
            <Layout>
                <Content />
            </Layout>

        </>
    );
}

export default News;
