import Content from '../../../components/aboutUs/otoraporMobil';
import Layout from '../../../components/layout';
import Expertise from '../../../components/dashboard/expertise-description';
function OtoraporMobil() {
  return (
    <>
     <Layout>
    <Content/>
    <Expertise/>

    </Layout>

    </>
  );
}

export default OtoraporMobil;
