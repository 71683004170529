import React, { useEffect, useState } from "react";
import './index.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, Grid } from 'swiper/modules';
import Expertise from "./expertise-description";
import {FaTags,  FaAddressBook} from "react-icons/fa";
import { FaUsersRays, FaLocationCrosshairs } from "react-icons/fa6";
import {GrMapLocation, } from "react-icons/gr";
import { Parallax} from 'swiper/modules';
import {Helmet} from "react-helmet";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css';
import Fade from 'react-reveal/Fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay'

function Dashboard() {
    const [sliderIndex, setSliderIndex] = useState(0); 

   

    const [showMore, setShowMore, FaQuestion] = useState(false);
    return (
        <>
          <Helmet>
        <title>Otorapor Balgat - Otoekspertiz Çankaya/ ANKARA</title>
        <meta name="description" content="Otorapor Balgat, güvenilir oto ekspertiz hizmetleri sunan öncü bir firmadır. Detaylı oto ekspertiz için Otorapor Balgat'a başvurun!"/>
        <link
          rel="canonical"
          href="https://www.otoraporbalgat.com/"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <h1 style={{display:"none"}}>Otorapor Balgat - Otoekspertiz Çankaya/ ANKARA</h1>
            <div className="dashboard-slider largeScreen">
            <Swiper
         pagination={{
            clickable: true,
          }}
          navigation={true}

          onSlideChange={(e) => setSliderIndex(e.realIndex)}
          centeredSlides={true}
        loop={true}
        autoplay={{
            delay: 8000,
            disableOnInteraction: false,
         }}
          modules={[Autoplay, Pagination, Navigation]}
        //   onActiveIndexChange={handleSlideChange} 
        className="mySwiper "
      >
   
             <SwiperSlide>
        <img src="/assets/images/home_slider/1.webp" />
        {sliderIndex === 0 && ( // Aktif slide'ın indexine göre Fade animasyonunu tetikleyin
          <Fade top delay={200}>
            <h2 className="sliderSlogan sliderSloganBir"><span className="buyukKalinFont">OTO EKSPERTİZDE</span><br/> <Fade top delay={1000}><span className="normalKalinFont">MÜKEMMELİ ARAYIN</span>       </Fade></h2>
          </Fade>
        )}
      </SwiperSlide>
      <SwiperSlide>
        <img src="/assets/images/home_slider/2.webp" />
        {sliderIndex === 1 && (
          <Fade right delay={200}>
            <h2 className="sliderSlogan sliderSloganIki"><span className="normalKalinFont">HER DETAYDA</span><br/><Fade top delay={1000}><span className="buyukKalinFont">GÜVENİLİRLİK</span></Fade></h2>
          </Fade>
        )}
      </SwiperSlide>
      <SwiperSlide>
        <img src="/assets/images/home_slider/3.webp" />
        {sliderIndex === 2 && (
          <Fade top delay={200}>
            <h2 className="sliderSlogan sliderSloganUc"><span className="normalKalinFont">GÜVENİLİR BİLGİ </span><br/><Fade top delay={1000}><span className="buyukKalinFont"> GÜVENİLİR SÜRÜŞ</span></Fade></h2>
          </Fade>
        )}
      </SwiperSlide>
      <SwiperSlide>
        <img src="/assets/images/home_slider/4.webp" />
        {sliderIndex === 3 && (
          <Fade right delay={200}>
            <h2 className="sliderSlogan sliderSloganDort"><span className="normalKalinFont">OTO EKSPERTİZDE</span> <br/><Fade top delay={1000}> <span className="buyukKalinFont">GÜVENİN ADI </span></Fade><br/><Fade top delay={2000}> <span className="normalKalinFont">OTORAPOR BALGAT</span></Fade></h2>
          </Fade>
        )}
      </SwiperSlide>
      <SwiperSlide>
        <img src="/assets/images/home_slider/5.webp" />
        {sliderIndex === 4 && (
          <Fade top delay={200}>
            <h2 className="sliderSlogan sliderSloganBes"> <span className="buyukKalinFont">OTORAPORLA GÜVENDE OL</span> <br/><Fade top delay={1000}> <span className="normalKalinFont">ARAÇ SEÇİMİNDE DOĞRU YOLDA OL</span></Fade></h2>
          </Fade>
        )}
      </SwiperSlide>
        
      </Swiper>
          
        

            </div>
            <div className="dashboard-slider smallScreen">
            <Swiper
         pagination={{
            clickable: true,
          }}
          navigation={true}

          onSlideChange={(e) => setSliderIndex(e.realIndex)}
          centeredSlides={true}
        loop={true}
        autoplay={{
            delay: 8000,
            disableOnInteraction: false,
         }}
          modules={[Autoplay, Pagination, Navigation]}
        //   onActiveIndexChange={handleSlideChange} 
        className="mySwiper "
      >
   
             <SwiperSlide>
        <img src="/assets/images/home_slider/1square.webp" />
        {sliderIndex === 0 && ( // Aktif slide'ın indexine göre Fade animasyonunu tetikleyin
          <Fade top delay={200}>
            <h2 className="sliderSlogan sliderSloganBir"><span className="buyukKalinFont">OTO EKSPERTİZDE</span><br/> <Fade top delay={1000}><span className="normalKalinFont">MÜKEMMELİ ARAYIN</span>       </Fade></h2>
          </Fade>
        )}
      </SwiperSlide>
      <SwiperSlide>
        <img src="/assets/images/home_slider/2square.webp" />
        {sliderIndex === 1 && (
          <Fade right delay={200}>
            <h2 className="sliderSlogan sliderSloganIki"><span className="normalKalinFont">HER DETAYDA</span><br/><Fade top delay={1000}><span className="buyukKalinFont">GÜVENİLİRLİK</span></Fade></h2>
          </Fade>
        )}
      </SwiperSlide>
      <SwiperSlide>
        <img src="/assets/images/home_slider/3square.webp" />
        {sliderIndex === 2 && (
          <Fade top delay={200}>
            <h2 className="sliderSlogan sliderSloganUc"><span className="normalKalinFont">GÜVENİLİR BİLGİ </span><br/><Fade top delay={1000}><span className="buyukKalinFont"> GÜVENİLİR SÜRÜŞ</span></Fade></h2>
          </Fade>
        )}
      </SwiperSlide>
      <SwiperSlide>
        <img src="/assets/images/home_slider/4square.webp" />
        {sliderIndex === 3 && (
          <Fade right delay={200}>
            <h2 className="sliderSlogan sliderSloganDort"><span className="normalKalinFont">OTO EKSPERTİZDE</span> <br/><Fade top delay={1000}> <span className="buyukKalinFont">GÜVENİN ADI </span></Fade><br/><Fade top delay={2000}> <span className="normalKalinFont">OTORAPOR BALGAT</span></Fade></h2>
          </Fade>
        )}
      </SwiperSlide>
      <SwiperSlide>
        <img src="/assets/images/home_slider/5square.webp" />
        {sliderIndex === 4 && (
          <Fade top delay={200}>
            <h2 className="sliderSlogan sliderSloganBes"> <span className="buyukKalinFont">OTORAPORLA GÜVENDE OL</span> <br/><Fade top delay={1000}> <span className="normalKalinFont">ARAÇ SEÇİMİNDE DOĞRU YOLDA OL</span></Fade></h2>
          </Fade>
        )}
      </SwiperSlide>
        
      </Swiper>
          
        

            </div>
            <div className="dashboard-content-one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 forty-card">
                                        <a target="_blank" href="https://otorapor.com/Oto-Ekspertiz-Fiyatlari">
                                        <div className="feature-box">
                                            <div><FaTags/></div>
                                            <div><b>Paketlerimiz</b>
                                                <br />
                                                <p>Her bütçeye uygun oto ekspertiz paketlerimiz için tıklayınız</p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 forty-card">
                                    <a href="/bize-ulasin">
                                        <div className="feature-box">
                                            <div><FaLocationCrosshairs/></div>
                                            <div><b>Bize Ulaşın </b>
                                                <br />
                                                <p>Noterden önce size en yakın otoekspertiz OTORATOR BALGAT</p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 forty-card">
                                    <a href="/biz-kimiz">

                                        <div className="feature-box">
                                            <div><FaUsersRays/></div>
                                            <div><b>Otorapor Kimdir</b>
                                                <br />
                                                <p>Türkiye'nin ilk Profesyonel Oto Ekspertiz Servisi OTORAPOR</p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 forty-card">
                                    <a target="_blank" href="https://otorapor.com/Arac/Randevu">

                                        <div className="feature-box">
                                            <div><FaAddressBook/></div>
                                            <div><b>Randevu Al !</b>
                                                <br />
                                                <p>Randevu al zaman kazan. Noterden önce size en yakın oto ekspertiz.</p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6">

                            <div class="pr-30-md">
                                <div class="section-heading white-color mt-30">
                                    <h2><strong>Oto Ekspertiz Sektörünün Mucidi</strong></h2>
                                    <h4>NE YAPIYORUZ ?</h4>
                                    <div class="section-heading-line-left"></div>
                                </div>
                                <div class="text-content white-color mt-25">
                                    <p>
                                        2007 yılından bu yana hizmet veren ilk ve tek profesyonel oto ekspertiz firması OTORAPOR.
                                        Alıcı ve satıcılara bir araya gelerek ekspertiz yaptırabilecekleri, anlaşabilecekleri, satın alacakları ya da satacakları araçla ilgili her türlü konuyu danışabilecekleri, fikir alabilecekleri ve pazarlık yapabilecekleri rahat bir ortam sunuyor. OTORAPOR’da her marka ikinci el otomobile Bağımsız Oto Ekspertiz Raporu düzenleniyor. Bunun için OTORAPOR'a satın almak istediğiniz aracın bilgilerini verebilir ve tercih ettiğiniz ekspertiz / raporlama paketini seçip randevu aldıktan sonra aracı size en yakın OTORAPOR servisine götürebilirsiniz.
                                    </p>
                                </div>

                                <a target="_blank" href="https://otorapor.com/arac/Randevu" title="Randevu Al" class="primary-button2 button-md mt-25 pull-right">RANDEVU AL</a>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {/* <div className="section-block-parallax homepagesocialicon ">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-12 text-right d-flex justify-content-center flex-wrap">
                            <a href="https://www.youtube.com/user/otorapor" target="_blank" title="Youtube Otorapor">
                                <img src="/assets/images/youtube.webp" loading="lazy" alt="Youtube Otorapor" width="120" height="120" />
                            </a>
                            <a href="https://www.instagram.com/otorapor" target="_blank" title="Instagram Otorapor">
                                <img src="/assets/images/instagram.webp" loading="lazy" alt="Instagram Otorapor" width="120" height="120" />
                            </a>
                            <a href="https://twitter.com/OTORAPOR" target="_blank" title="Twitter Otorapor">
                                <img src="/assets/images/twitter-logo.webp" loading="lazy" alt="Twitter Otorapor" width="120" height="120" />
                            </a>
                            <a href="https://www.facebook.com/otoraporofficial" target="_blank" title="Facebook Otorapor">
                                <img src="/assets/images/facebook.webp" loading="lazy" alt="Facebook Otorapor" width="120" height="120" />
                            </a>
                            <a href="https://www.linkedin.com/company/18020513" target="_blank" title="Linkedin Otorapor">
                                <img src="/assets/images/linkedin.webp" loading="lazy" alt="Linkedin Otorapor" width="120" height="120" />
                            </a>
                            <a href="https://open.spotify.com/user/6oe7hp6ua0jf9e7h830pyhmc4" target="_blank" title="Spotify Otorapor">
                                <img src="/assets/images/spotify.webp" loading="lazy" class="Spotify" width="120" height="120" alt="Spotify Otorapor" />
                            </a>
                            <a href="https://www.tiktok.com/@otoraportv" target="_blank" title="Tiktok Otorapor">
                                <img src="/assets/images/tiktok.webp" loading="lazy" class="Tiktok" width="120" height="120" alt="Tiktok Otorapor" />
                            </a>
                            <a href="https://www.threads.net/@otorapor" target="_blank" title="Threads Otorapor">
                                <img src="/assets/images/threads.webp" loading="lazy" class="Tiktok" width="120" height="120" alt="Threads Otorapor" />
                            </a>
                        </div>

                    </div>
                </div>

                

            </div> */}
            <div className="section-swiper largeScreen">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <Swiper
                            slidesPerView={3}
                            pagination={{
                                clickable: true,
                            }}
                            spaceBetween={30}

                            grid={{
                                rows: 1
                              }}
                            modules={[Grid, Pagination]}
                            className="mySwiper"
                        >
                            <SwiperSlide><img src="/assets/images/banner/banner2.webp" /></SwiperSlide>
                            <SwiperSlide><img src="/assets/images/banner/banner3.webp" /></SwiperSlide>
                            <SwiperSlide><img src="/assets/images/banner/banner4.webp" /></SwiperSlide>
                            <SwiperSlide><img src="/assets/images/banner/banner5.webp" /></SwiperSlide>

                        </Swiper>
                    </div>
                </div>
            </div>
            <div className="section-swiper smallScreen">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <Swiper
                            slidesPerView={1}
                            pagination={{
                                clickable: true,
                            }}
                            spaceBetween={30}

                            grid={{
                                rows: 1
                              }}
                            modules={[Grid, Pagination]}
                            className="mySwiper"
                        >
                            <SwiperSlide><img src="/assets/images/banner/banner2.webp" /></SwiperSlide>
                            <SwiperSlide><img src="/assets/images/banner/banner3.webp" /></SwiperSlide>
                            <SwiperSlide><img src="/assets/images/banner/banner4.webp" /></SwiperSlide>
                            <SwiperSlide><img src="/assets/images/banner/banner5.webp" /></SwiperSlide>

                        </Swiper>
                    </div>
                </div>
            </div>
            <Expertise />


        </>

    );
}

export default Dashboard;