import Content from '../../components/contact';
import Layout from '../../components/layout';
import Expertise from '../../components/dashboard/expertise-description';

function Contact() {
  return (
    <>
     <Layout>
    <Content/>
    <Expertise/>

    </Layout>

    </>
  );
}

export default Contact;
