
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Dashboard from './pages/dashboard';
import WhoWeAre from './pages/aboutUs/whoWeAre';
import WhatWeDoing from './pages/aboutUs/whatWeDoing';
import Reference from './pages/aboutUs/reference';
import QualityDocument from './pages/aboutUs/qualityDocument';
import News from './pages/aboutUs/news';
import OtoraporTv from './pages/aboutUs/otoraporTv';
import Price from './pages/price';
import OtoraporMobil from './pages/aboutUs/otoraporMobil';
import Contact from './pages/contact';
import Faq from './pages/aboutUs/faq';



import './App.css';
import { FaQ } from 'react-icons/fa6';
import MainIcons from './components/mainIcons/MainIcons';
import OtoMr from './pages/otoMr';


function App() {
  return (
    <>
      <Router basename='/'>
        <Routes>
     
          <Route exact path='/' element={<Dashboard />} />
          <Route exact path='/biz-kimiz' element={<WhoWeAre />} />
          <Route exact path='/sikca-sorulan-sorular' element={<Faq />}   />
          <Route exact path='/ne-yapiyoruz' element={<WhatWeDoing />} />
          <Route exact path='/referanslarimiz' element={<Reference />} />
          <Route exact path='/kalite-belgelerimiz' element={<QualityDocument />} />
          <Route exact path='/basinda-biz' element={<News />} />
          <Route exact path='/otorapor-tv' element={<OtoraporTv />} />
          <Route exact path='/otorapor-mobil' element={<OtoraporMobil />} />
          <Route exact path='/oto-ekspertiz-fiyatlari' element={<Price />} />
          <Route exact path='/bize-ulasin' element={<Contact />} />
          <Route exact path='/oto-mr-sistemi-ankara' element={<OtoMr />} />

  
        </Routes>
        <MainIcons/>
      </Router>

    </>
  );
}

export default App;
