import Content from '../../../components/aboutUs/reference';

import Layout from '../../../components/layout';

function Reference() {
  return (
    <>
     <Layout>
    <Content/>
    </Layout>

    </>
  );
}

export default Reference;
