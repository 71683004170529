import React, { useState, useRef } from "react";
import Banner from "../banner";
import "./index.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";

function Contact(props) {
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const [isFormError, setIsFormError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState();
  const form = useRef();

  function onChange(value) {
    setValue(value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsFormSuccess(false);
    setIsFormError(false);
    if (!isSubmitting) {
      setIsSubmitting(true);

      const FD2 = new FormData(form.current);
      const message =
        "<strong>Mesajınız : </strong>" +
        FD2.get("message") +
        " <br/> <br/> <strong>İş Emri No : </strong> " +
        FD2.get("IsEmriNo") +
        " <br/> <br/> <strong>Plaka : </strong> " +
        FD2.get("Plaka");
      const subject = FD2.get("subject");
      const userInfo = FD2.get("userInfo");
      const from = FD2.get("from");
      const tel = FD2.get("tel");

      const FD = new FormData();
      FD.append("ownerName", process.env.REACT_APP_OWNER_NAME);
      FD.append("to", "balgat@otorapor.com");
      // FD.append("ownerPassword", process.env.REACT_APP_OWNER_PASSWORD);
      FD.append("host", process.env.REACT_APP_OWNER_EMAIL_HOST);
      FD.append("service", process.env.REACT_APP_OWNER_EMAIL_SERVICE);
      FD.append("message", message);
      FD.append("subject", subject);
      FD.append("userInfo", userInfo);
      FD.append("from", from);
      FD.append("tel", tel);
      try {
        const res = await axios.post(process.env.REACT_APP_EMAIL_SERVER, FD, {
          auth: {
            username: process.env.REACT_APP_OWNER_EMAIL_NAME,
            password: process.env.REACT_APP_OWNER_EMAIL_PASSWORD,
          },
        });
        if (res) {
          setIsFormSuccess(true);
          toast.success("Mesajınız başarıyla gönderildi.");
          console.log("mesajınız gönderildi.");
        }
      } catch (error) {
        setIsFormError(true);
        console.log(error);
        toast.error("Mesajınız gönderilemedi.");
        console.log("mesajınız gönderilemedi.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
     <Helmet>
        <title>İletişim - Otorapor Balgat</title>
        <meta name="description" content="Otorapor Balgat olarak, siz değerli müşterilerimize en iyi hizmeti sunabilmek için her zaman yanınızdayız."/>
        <link
          rel="canonical"
          href="https://www.otoraporbalgat.com/bize-ulasin"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
   
      <h1 style={{display:"none"}}>İletişim Otorapor</h1>
      <Banner url={"/bize-ulasin"} text={"Bize Ulaşın"} />
      <div class="section-block">
        <div class="container">
          <div class="section-heading">
            <h5>İletişim Formu</h5>
            <div class="section-heading-line-left"></div>
          </div>
          <div class="row mt-30">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-5">
              <div class="row">
                <div class="col-12 col-md-12">
                  <hr />
                  <form class="form-contact" ref={form} onSubmit={handleSubmit}>
                    <b>Plaka</b>
                    <div class="form-group">
                      <input
                        type="text"
                        name="Plaka"
                        id="Plaka"
                        class="form-control zorunlu"
                        placeholder="İlgili aracın plakası"
                      />
                    </div>
                    <b>İş Emri Kodu</b>
                    <div class="form-group">
                      <input
                        type="text"
                        name="IsEmriNo"
                        id="IsEmriNo"
                        class="form-control zorunlu"
                        placeholder="Rapor sayfalarında sol en üstte bulunan kod. Örn: MPM010101"
                      />
                    </div>
                    <b>Konu</b>
                    <br />
                    <div class="form-group">
                      <select
                        name="subject"
                        class="form-control zorunlu"
                        id="KonuID"
                      >
                        <option value="">Seçiniz</option>
                        <option value="Teşekkür">Teşekkür</option>
                        <option value="Öneri &amp; Geri Bildirim">
                          Öneri &amp; Geri Bildirim
                        </option>
                        <option value="Bayi Şikayetleri">
                          Bayi Şikayetleri
                        </option>
                        <option value="Kaporta Boya Şikayeti">
                          Kaporta Boya Şikayeti
                        </option>
                        <option value="Motor Mekanik Şikayeti">
                          Motor Mekanik Şikayeti
                        </option>
                        <option value="Diğer Şikayetler">
                          Diğer Şikayetler
                        </option>
                        <option value="Personel ile ilgili şikayet">
                          Personel ile ilgili şikayet
                        </option>
                      </select>
                    </div>
                    <b>Mesajınız</b>
                    <div class="form-group">
                      <textarea
                        name="message"
                        id="Aciklama"
                        class="form-control zorunlu"
                        rows="10"
                        cols="20"
                        placeholder="Mesajınız..."
                      ></textarea>
                    </div>
                    <b>Ad Soyad</b>
                    <div class="form-group">
                      <input
                        type="text"
                        name="userInfo"
                        id="AdSoyad"
                        class="form-control zorunlu"
                        placeholder="Adınız soyadınız"
                      />
                    </div>
                    <b>E-Mail Adresi</b>
                    <div class="form-group">
                      <input
                        type="email"
                        name="from"
                        id="Email"
                        class="form-control zorunlu"
                        placeholder="E-Mail Adresi"
                      />
                    </div>
                    <b>Telefon Numarası</b>
                    <div class="form-group">
                      <input
                        type="tel"
                        name="tel"
                        id="Telefon"
                        class="form-control"
                        placeholder="Telefon Numarası"
                      />
                    </div>
                    <ReCAPTCHA
                      sitekey="6Lcjv1UoAAAAAK-px7bjdXu-bFh0H9eH9ArwARIF"
                      onChange={onChange}
                      theme="light"
                    />

                    <div class="form-group">
                      <br />
                      <input
                        type="submit"
                        class="primary-button2 contact-button"
                        value="Gönder"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-12">
                  <a href="tel:+905424425060">
                    <div class="contact-box">
                      <i class="fa fa-phone-square" aria-hidden="true"></i>
                      <h4>İletişim</h4>{" "}
                      <span>
                        Şimdi bayilerimiz ile iletişime geçin.
                        <br />
                      </span>
                    </div>
                  </a>
                </div>
                <div class="col-md-6 col-sm-6 col-12">
                  <a target="_blank" href="https://otorapor.com/arac/sikayet">
                    <div class="contact-box">
                      <i class="fa fa-comments" aria-hidden="true"></i>
                      <h4>Tüketici Şikayetleri</h4>{" "}
                      <span>
                        Mesajınızı iletmek için tıklayın.
                        <br />
                      </span>
                    </div>
                  </a>
                </div>
                <div class="col-md-6 col-sm-6 col-12">
                  <a href="/sikca-sorulan-sorular">
                    <div class="contact-box">
                      <i class="fa fa-comment-dots" aria-hidden="true"></i>
                      <h4>Sıkça Sorulan Sorular</h4>{" "}
                      <span>En sık karşılaştığımız soruları inceleyin.</span>
                    </div>
                  </a>
                </div>
                <div class="col-md-6 col-sm-6 col-12">
                  <a href="mailto:kurumsaliletisim@otorapor.com">
                    <div class="contact-box">
                      <i class="fa fa-envelope-square" aria-hidden="true"></i>
                      <h4>Kurumsal ilişkiler için</h4>{" "}
                      <a target="_blank" href="mailto:balgat@otorapor.com">
                        <span> balgat@otorapor.com </span>
                      </a>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default Contact;
