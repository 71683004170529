import React,{useRef} from "react";
import "./index.css";
import Banner from "../../banner";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";


function WhoWeAre() {

  
  return (
    <>
      <Banner url={"/sikca-sorulan-sorular"} text={"Sıkça Sorulan Sorular"} />
      <div className="section-block">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12 mb-5">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Otorapor Akademi Nedir?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Otorapor, ekspertiz uzmanlarını kendi akademisinde
                      yetiştirmektedir. Otomobil sektöründe tecrübeli
                      personellere seçilerek eğitim verilmektedir ve eğitimi
                      başarıyla tamamlayan adaylar Otorapor bayilerinde
                      görevlendirilmektedir.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Otorapor Aracın Masrafları Hakkında Bilgi Verir mi?{" "}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Otorapor aracın kusurlu noktaları hakkında bilgi
                      vermektedir. Ancak bu noktaların tamir ücretinin ne kadar
                      olacağı hakkında kesinlikle fikir beyan etmemektedir.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Otorapor Bağımsız Ekspertiz Ne Anlama Gelmektedir?{" "}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Otorapor hem alıcıya hem de satıcıya eş zamanlı, objektif
                      hizmet sunmaktadır. Hiçbir ticari veya bireysel müşteriye
                      imtiyaz tanımadan ekspertiz raporunu oluşturmaktadır.
                      Ayrıca Otorapor personelleri tarafından araç
                      bakım/servis/tamir kuruluşlarına yönlendirme
                      yapılmamaktadır.{" "}
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      501 Nokta Ekspertiz Nedir?{" "}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Otorapor, aracın ekspertizi sırasında kontrol
                      edilebileceği 501 noktanın tamamını belirlemiş ve
                      literatüre kazandırmıştır.{" "}
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Otorapor Araç Tamiri Yapar mı?{" "}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Otorapor sadece ekspertiz hizmeti vermektedir. Hiçbir
                      koşulda araç tamir, bakım, parça değişimi, araç yıkama vb.
                      hizmetler sunmamaktadır.{" "}
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Otorapor Mobil Nedir?{" "}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Otorapor mobil, ekspertiz işlemlerinizi kolayca yapmanızı
                      sağlayan, her an ulaşabileceğiniz bir mobil uygulamadır.
                      Detaylı bilgi için:{" "}
                      <a
                        target="_blank"
                        href="https://www.otorapor.com/otorapor-mobil"
                      >
                        {" "}
                        https://www.otorapor.com/otorapor-mobil{" "}
                      </a>{" "}
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Otorapor Çalışma Saatleri{" "}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Otorapor bayileri hafta içi, cumartesi ve pazar günleri
                      09:00-18:00 arasında hizmet vermektedir. Talebiniz
                      doğrultusunda uygunluk durumuna göre çalışma saatlerinde
                      esneklik gösterebilmektedir. Detaylı bilgi almak için
                      hizmet almak istediğiniz Otorapor bayileri ile iletişime
                      geçebilirsiniz."
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Oto Ekspertiz Ne İşe Yarar?{" "}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Oto ekspertiz, beğendiğiniz bir aracı satın almadan önce
                      detaylı bir şekilde kontrol edilmesini ve varsa
                      kusurlarının tespit edilerek gönül rahatlığıyla satın
                      almanıza karar vermenizi sağlamaktadır. Ayrıca, satıcılar
                      ekspertiz yaptırdığı zaman aracını şeffaf bir şekilde
                      ilana koyduğu için daha fazla müşteriye hitap
                      edebilmektedir.{" "}
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Oto Ekspertiz Ücretini Kim Öder?{" "}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Ekspertiz ücretinin ödemesi normal şartlarda alıcı
                      tarafından gerçekleştirilmektedir. Çünkü aracı satın alma
                      ve kontrollerini sağlama niyetinde olan taraf alıcıdır.
                      Ancak bazı durumlarda bu değişkenlik gösterebilir. Alıcı
                      ile satıcının arasındaki anlaşmaya göre, araçta satıcının
                      beyan ettiği durumlar dışında bir kusurun tespit edilmesi
                      durumunda, iki tarafın anlaşmazlığa düşmesi vb. durumlarda
                      ödeme satıcı tarafından veya ortaklaşa
                      gerçekleştirilebilir. Bu gibi olaylar için her iki tarafın
                      da ekspertizden önce anlaşarak işlem yaptırmasını
                      öneririz.{" "}
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Oto Ekspertiz Raporu Ne Kadar Geçerli?{" "}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Ekspertiz raporunun herhangi bir geçerlilik süresi
                      bulunmamaktadır. Ancak, zaman geçtikçe ve aracın kat
                      ettiği mesafe arttıkça rapor güncelliğini yitirmektedir.{" "}
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Otorapor Ekspertiz Ne Kadar Sürer?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Ekspertiz süresi, satın almış olduğunuz paketin içeriğine
                      göre değişkenlik göstermektedir. Oto ekspertiz yaklaşık
                      olarak 30 dakika ile 60 dakika arasında tamamlanmaktadır.
                      Bayilerimizdeki yoğunluk bu süreye etki edebilir.
                      Dolayısıyla Randevu alarak bayiye gitmenizi öneririz.{" "}
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Otorapor Ekspertiz Fiyatları Ne Kadar?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Güncel paket ve fiyat bilgilerimize Fiyatlarımız
                      sayfasından ulaşabilirsiniz{" "}
                    </p>{" "}
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <div className="pl-30-md asdaf">
                <div className="section-heading">
                  <h4>Oto Ekspertiz Nedir?</h4>
                </div>
                <div className="section-heading-line-left" />
                {/* <div className="text-content-big mt-20">
                  <p>
                    OTORAPOR, teknik cihaz, ekipman ve robotlarla ikinci el
                    araçların kaza/arıza durumlarını test ve muayene edip
                    detaylı oto ekspertiz raporu düzenleyen, Türkiye’nin ilk
                    profesyonel, tarafsız, bağımsız ve tam donanımlı oto
                    ekspertiz kuruluşu ve ikinci el oto test check-up
                    merkezidir. 2007 yılında İstanbul’da faaliyete başlayan
                    OTORAPOR, bugün ülke çapına yayılmış bayi ağı ile birçok
                    yerli ve ithal aracın alım – satım öncesi ekspertizini
                    bağımsızlık ilkesiyle hızlı ve güvenilir bir şekilde
                    gerçekleştirmektedir.
                  </p>
                </div> */}
                <div className="feedback-box">
                  <iframe
                    style={{ width: "100%" }}
                    height={315}
                    src="https://www.youtube.com/embed/1LJM-KvZVtg"
                    frameBorder={0}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
                <div className="section-heading mt-5">
                  <h4>Oto Ekspertiz Nedir?</h4>
                  <div className="section-heading-line-left" />
                </div>
                <div className="row mt-20">
                  <div className="feedback-box">
                    <iframe
                      style={{ width: "100%" }}
                      height={315}
                      src="https://www.youtube.com/embed/c73WM6lQ9eo"
                      frameBorder={0}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhoWeAre;
