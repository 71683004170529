import React, { useState } from "react";
import './index.css';

function Banner({url,text}) {
    return (
        <>
    <div class="page-title-section" >
    <div class="container">
        <h1>{text}</h1>
        <ul>
            <li><a href="/">Anasayfa</a></li> <span className="m-3 text-white"><b>&gt;</b></span>
            <li><a href={url}>{text}</a></li>
        </ul>
    </div>
</div>

        </>

    );
}

export default Banner;