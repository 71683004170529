import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { imageData } from '../../data/reference'

import { Scrollbar } from 'swiper/modules';
function Brand() {
    return (
        <>
            <Swiper
                slidesPerView={4}
                scrollbar={{
                    hide: true,
                }}
                modules={[Scrollbar]}
                className="mySwiper"
            >
                {imageData.length > 0 ? imageData.map((key) => {
                    return (
                        <SwiperSlide>
                            <div class="col-md-6 col-sm-6 col-8">

                                <div class="project-grid">
                                    <img src={key.img} alt={key.text} widh="180" height="180" />
                                </div>

                            </div>
                        </SwiperSlide>

                    )
                }) : null}
            </Swiper>
        </>

    );
}

export default Brand;

