 export const  imageData = [
    {
        img: "/assets/images/reference/s_thy_logo.webp", alt: "Türk Hava Yolları", text: "Türk Hava Yolları"
    }, {
        img: "/assets/images/reference/s_aljfinans_logo.webp", alt: "ALJ Finans", text: "ALJ Finans"
    }, {
        img: "/assets/images/reference/s_s_ZES_YENI_LOGO_RGB_01.webp", alt: "ZES", text: "ZES"
    }, {
        img: "/assets/images/reference/s_toyota.webp", alt: "Toyota", text: "Toyota"
    }, {
        img: "/assets/images/reference/s_migros_logo.webp", alt: "Migros", text: "Migros"
    }, {
        img: "/assets/images/reference/s_ekspertizrehberi_logo.webp", alt: "Ekspertiz Rehberi", text: "Ekspertiz Rehberi"
    }, {
        img: "/assets/images/reference/s_autorola_logo.webp", alt: "Autorola", text: "Autorola"
    }, {
        img: "/assets/images/reference/s_birtep_logo.webp", alt: "Birtep", text: "Birtep"
    }, {
        img: "/assets/images/reference/s_eminoto_logo.webp", alt: "Eminoto", text: "Eminoto"
    }, {
        img: "/assets/images/reference/s_esan_logo.webp", alt: "ESAN", text: "ESAN"
    }, {
        img: "/assets/images/reference/s_wowwoLogo.webp", alt: "Wowwo", text: "Wowwo"
    }, {
        img: "/assets/images/reference/s_borusan.webp", alt: "Borusan", text: "Borusan"
    }, {
        img: "/assets/images/reference/s_cetas_logo.webp", alt: "Çetaş", text: "Çetaş"
    }, {
        img: "/assets/images/reference/s_kuveytturk_logo.webp", alt: "Kuveyt Türk", text: "Kuveyt Türk"
    }, {
        img: "/assets/images/reference/s_mengerler_logo.webp", alt: "Mengerler", text: "Mengerler"
    }, {
        img: "/assets/images/reference/s_Getir_Logo.webp", alt: "Getir", text: "Getir"
    }, {
        img: "/assets/images/reference/s_koc_universitesi_logo.webp", alt: "Koç Üniversitesi", text: "Koç Üniversitesi"
    }, {
        img: "/assets/images/reference/s_ermat_logo.webp", alt: "Ermat", text: "Ermat"
    }, {
        img: "/assets/images/reference/s_expert_assist_logo.webp", alt: "Expert Assist", text: "Expert Assist"
    }, {
        img: "/assets/images/reference/s_Otolira_logo.webp", alt: "Otolira", text: "Otolira"
    }, {
        img: "/assets/images/reference/s_otomerkezi_logo.webp", alt: "Oto Merkezi", text: "Oto Merkezi"
    }, {
        img: "/assets/images/reference/s_ikincieotomobilim_logo.webp", alt: "ikincielotomobilim.com", text: "ikincielotomobilim.com"
    }, {
        img: "/assets/images/reference/s_gülsoy.webp", alt: "Gülsoy Otomotiv", text: "Gülsoy Otomotiv"
    }, {
        img: "/assets/images/reference/s_kocfinans_logo.webp", alt: "Koç Finans", text: "Koç Finans"
    }, {
        img: "/assets/images/reference/s_allianz_sigorta.webp", alt: "Allianz", text: "Allianz"
    }, {
        img: "/assets/images/reference/s_images.webp", alt: "Halkbank", text: "Halkbank"
    }, {
        img: "/assets/images/reference/s_albaraka.webp", alt: "alBaraka", text: "alBaraka"
    }, {
        img: "/assets/images/reference/s_ilab_logo.webp", alt: "iLab", text: "iLab"
    }, {
        img: "/assets/images/reference/s_afs_logo.webp", alt: "AFS Tasarım", text: "AFS Tasarım"
    }, {
        img: "/assets/images/reference/s_kontroledelim_logo.webp", alt: "Kontrol Edelim", text: "Kontrol Edelim"
    }, {
        img: "/assets/images/reference/s_indicata.webp", alt: "INDICATA", text: "INDICATA"
    }, {
        img: "/assets/images/reference/s_otoarsiv_logo.webp", alt: "Otoarşiv", text: "Otoarşiv"
    }, {
        img: "/assets/images/reference/s_Ziebart_logo.webp", alt: "Ziebart", text: "Ziebart"
    }, {
        img: "/assets/images/reference/s_celik_motor_filo_standartlari_belirliyor_h1410522053.webp", alt: "Çelik Motor Filo", text: "Çelik Motor Filo"
    }, {
        img: "/assets/images/reference/s_garenta_logo.webp", alt: "Garenta", text: "Garenta"
    }, {
        img: "/assets/images/reference/s_tamirteklif_logo.webp", alt: " Tamirteklif", text: " Tamirteklif"
    }, {
        img: "/assets/images/reference/s_koluman.webp", alt: "Koluman", text: "Koluman"
    }, {
        img: "/assets/images/reference/s_suzuki_logo.webp", alt: "Suzuki", text: "Suzuki"
    }
]
