import React , {useState} from "react";
import Banner from "../banner";
import { Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import './index.css'
function Price() {
    const [filterEkspertise,setFilterEkspertise] = useState([])
    var imagePrice = [
        {
            img: "/assets/images/zam_yok_kampanyasi.webp"
        }, {
            img: "/assets/images/esnaf_kampanyasi_0.webp"
        }, {
            img: "/assets/images/Kamu_Personeli_İndirimli_Ekspertiz_0.webp"
        }, {
            img: "/assets/images/rezervasyon_ekspertiz_kampanyasi_0.webp"
        }, {
            img: "/assets/images/ziebart_otorapor_indirimi.webp"
        },

    ]
    var expertiseFilter = [
        {
            img: "/assets/images/ekspertiz/2.png"
        }, {
            img: "/assets/images/ekspertiz/4.png"
        }, {
            img: "/assets/images/ekspertiz/5.png"
        }, {
            img: "/assets/images/ekspertiz/17.png"
        }, {
            img: "/assets/images/ekspertiz/8.png"
        }, {
            img: "/assets/images/ekspertiz/10.png"
        }, {
            img: "/assets/images/ekspertiz/11.png"
        }, {
            img: "/assets/images/ekspertiz/18.png"
        }, {
            img: "/assets/images/ekspertiz/14.png"
        }, {
            img: "/assets/images/ekspertiz/15.png"
        }

    ]
    var expertisePrice = [
        {
            title: "BRONZ PAKET",
            price: "2.500,00",
            img: "/assets/images/products/BRONZ_PAKET.webp"
        },
        {
            title: "SILVER PAKET",
            price: "3.000,00",
            img: "/assets/images/products/SILVER_PAKET.webp"
        },
        {
            title: "SILVER PLUS PAKET",
            price: "3.250,00",
            img: "/assets/images/products/SILVER_PLUS_PAKET.webp"
        },
        {
            title: "GOLD PAKET",
            price: "3.500,00",
            img: "/assets/images/products/GOLD_PAKET.webp"
        },
        {
            title: "GOLD PLUS PAKET",
            price: "4.200,00",
            img: "/assets/images/products/GOLD_PLUS_PAKET.webp"
        }, {
            title: "FULL PAKET",
            price: "4.500,00",
            img: "/assets/images/products/FULL_PAKET.webp"
        }, {
            title: "GARANTİLİ FULL PAKET",
            price: "6.000,00",
            img: "/assets/images/products/GARANTILI_FULL_PAKET.webp"
        }, {
            title: "LUXURY PAKET",
            price: "9.000,00",
            img: "/assets/images/products/LUXURY_PAKET.webp"
        }, {
            title: "VIP HAVAALANI PAKETİ",
            price: "15.000,00",
            img: "/assets/images/products/VIP_HAVAALANI_PAKETI.webp"
        }, {
            title: "AIRBAG KONTROL PAKETİ",
            price: "1.750,00",
            img: "/assets/images/products/AIRBAG_KONTROL_PAKETI.webp"
        }, {
            title: "CONTA KAÇAK TESTİ",
            price: "700,00",
            img: "/assets/images/products/CONTA_KACAK_TESTI.webp"
        }, {
            title: "Termal Kamera(Emar-MR) Paketi",
            price: "2.500,00",
            img: "/assets/images/products/Termal_KameraEmar_MR_Paketi.webp"
        }, {
            title: "Termal Kamera(Emar-MR) Kontrollü Kaporta/Boya Paketi",
            price: "3.500,00",
            img: "/assets/images/products/Termal_KameraEmar_MR_Kontrollu_Kaporta_Boya_Paketi.webp"
        },
    ]
    const handleButtonClick = (e, newClass, newFilter) => {
        e.preventDefault();
        setFilterEkspertise(newFilter);
    
        const buttons = document.querySelectorAll('.btn');
        buttons.forEach((button) => {
          button.classList.remove('active-btn'); // Önceki aktif class'ı temizle
        });
        e.target.classList.add(newClass); // Yeni class'ı ekle
      };
    
    return (
        <>
            <Banner url={"/oto-ekspertiz-fiyatlari"} text={"Oto Ekspertiz Fiyatları"} />

            <div className="section-block-grey">
                <div className="container">
                    <div className="row mt-40">
                        <Swiper
                            slidesPerView={3}
                            scrollbar={{
                                hide: true,
                            }}
                            modules={[Scrollbar]}
                            className="priceswiper"
                        >
                            {imagePrice.length > 0 ? imagePrice.map((key) => {
                                return (
                                    <SwiperSlide>
                                        <div class="blog-grid-simple">
                                            <a href="#" target="" >
                                                <img src={key.img} loading="lazy" alt="Fiyatlara Zam Yok!" />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                )
                            }) : null}
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className="section-block">
                <div className="container">
                    <div class="section-heading">
                        <h5>Oto Ekspertiz Fiyatları</h5>
                        <div class="section-heading-line-left"></div>
                    </div>
                    <div className="row mt-40">
                        {expertisePrice.length > 0 ? expertisePrice.map((key, index) => {
                            return (
                                <div className="col-md-4 col-sm-6 col-12">
                                    <div class="shop-grid" stok="1">

                                        <div class="shop-grid-img">
                                            <a href="/bronz-paket"> <img src={key.img} alt="BRONZ PAKET" /></a>
                                        </div>
                                        <div class="shop-grid-info text-center" >
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 pr-0">
                                                    <h4><a href="/bronz-paket">{key.title}</a></h4>
                                                    <span></span>
                                                    <h5 className="text-center" stok="1">
                                                        <small>{key.price} <em>₺</em></small>
                                                    </h5>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : null}








                    </div>
                </div>
                <div class="container">
                    <div class="container_inner default_template_holder clearfix">
                        <div class="column_inner">
                            <div class="noktalar">
                                <div class="buttons">
                                <button
        className="btn"
        onClick={(e) =>
          handleButtonClick(e, 'active-btn', [2, 4, 7, 8])
        }
      >
        BRONZ PAKET(2500₺)
      </button>
      <button
        className="btn"
        onClick={(e) =>
          handleButtonClick(e, 'active-btn', [2, 4, 5, 7, 8, 9])
        }
      >
        SILVER PAKET(3000₺)
      </button>
      <button
        className="btn"
        onClick={(e) =>
          handleButtonClick(e, 'active-btn', [0, 1, 2, 3, 4, 5, 6, 7, 8, 9])
        }
      >
        GOLD PLUS PAKET(4200₺)
      </button>
                                    </div>
                                <div class="parts">
                                    {expertiseFilter.length > 0 ? expertiseFilter.map((key,index) => {
                                        const result = filterEkspertise.filter((item)=>item == index)
                                        
                                            if(result.length>0){
                                                return(
                                                    <><a onclick="noktaModal(5)"><img src={key.img} group="5" alt="" class="tile-animated tile-active" /></a></>
                                                )
                                            }else{
                                                return(
                                                <><a onclick="noktaModal(5)"><img src={key.img} group="5" alt="" class="" /></a></>
                                                )
                                            }
                                      
                                    }) : null}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>

    );
}

export default Price;

