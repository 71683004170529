import React from "react";
import './index.css';
import Banner from "../../banner";
import {imageData} from '../../../data/reference'
import {Helmet} from "react-helmet";

function Reference() {
    
    return (
        <>
          <Helmet>
        <title>Referanslarımız - Otorapor Balgat</title>
        <meta name="description" content="Otorapor Balgat olarak, müşteri memnuniyetini sağlamak ve güvenilirlik ilkesine bağlı kalmak için çaba gösteriyoruz."/>
        <link
          rel="canonical"
          href="https://www.otoraporbalgat.com/referanslarimiz"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <h1 style={{display:"none"}}>Referanslarımız Otorapor</h1>
            <Banner url={"/referanslarimiz"} text={"REFERANSLARIMIZ"} />
            <div className="section-block">
                <div className="container">
                    <div class="section-heading">
                        <h4>REFERANSLARIMIZ</h4>
                        <div class="section-heading-line-left"> </div>

                    </div>
                    <div className="row mt-50 justify-content-center">
                        {imageData.length > 0 ? imageData.map((key) => {
                            return (
                                <div class="col-md-2 col-sm-4 col-6 ">

                                    <div class="col-md-12 col-12">
                                        <div class="reference project-grid" >
                                            <div class="project-grid-img">
                                                <img src={key.img} alt={key.text} widh="151" height="151" />
                                            </div>
                                            <div class="project-grid-overlay">
                                                <h4><span>{key.text}</span></h4>
                                                <p></p>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        }) : null}
                    </div>
                </div>
            </div>
        </>

    );
}

export default Reference;