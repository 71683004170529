import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../mobileMenu";
import OffsetMenu from "../offsetMenu";
import MainMenu from "./MainMenu";
import Topbar from "./Topbar";
import "./index.css";

function Header() {
  const [offset, setOffset] = useState(false);
  const handleOffset = () => {
    setOffset(!offset);
  };
  return (
    <>
      <OffsetMenu
        handleOffset={handleOffset}
        className={offset ? "offset-menu show" : "offset-menu"}
      />
      <header className="header-wrap header-1">
        <Topbar />
        <div className="container">
          <div className="row align-items-center flex-nowrap">
            <div className="col-8 col-lg-3">
              <div className="logo">
                <Link to="/">
                  <img id="header-logo" src="/assets/images/logo.png" alt="logo" />
                </Link>
              </div>
            </div>
            <div className="col-4 d-block d-lg-none">
              <div className="mobile-nav-wrap">
                <MobileMenu />
              </div>
            </div>
            <div className="col-lg-9 menu-wrapper d-none d-lg-block">
            
                <MainMenu hanldeOffset={handleOffset} />
            
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
