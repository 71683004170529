import React from 'react';
import { FaEnvelopeOpenText, FaMap, FaPhone  } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const MenuContent = () => (
    <div className="mobile-menu-bottom">
        <ul>
            <li className="bottom-item">
                <FaEnvelopeOpenText size={20}    className="icon" />
                <a href='mailto:info@otoraporbalgat.com'>info@otoraporbalgat.com</a>
            </li>
            <li className="bottom-item">
                <FaPhone  size={20}    className="icon" />
                <a href='tel:05424425060'>0542 442 50 60</a>
            </li>
       
        <li className="bottom-btn">
        <FaMap   size={20}  className="icon" />
        <a target='_"blank' href='https://goo.gl/maps/Kv7tq9Zn5XA2H4jaA'>Konum</a>

        </li> </ul>
    </div>
);

export default MenuContent;
