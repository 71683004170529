import React from "react";
import "./index.css";
import Banner from "../../banner";
import { Helmet } from "react-helmet";

function QualityDocument() {
  var imageArr = [
    {
      img: "/assets/images/qualityDocument/s_sertifika2.jpg",
      alt: "100% Tarafsız",
      text: "100% Tarafsız",
    },
    {
      img: "/assets/images/qualityDocument/s_sertifika3.jpg",
      alt: "Tarafsız Titiz Uzman",
      text: "Tarafsız Titiz Uzman",
    },
    {
      img: "/assets/images/qualityDocument/s_sertifika4.jpg",
      alt: "ISO 14001:2004",
      text: "ISO 14001:2004",
    },
    {
      img: "/assets/images/qualityDocument/s_sertifika5.jpg",
      alt: "OHSAS 18001:2007",
      text: "OHSAS 18001:2007",
    },
    {
      img: "/assets/images/qualityDocument/s_sertifika6.jpg",
      alt: "ISO 10002:2004",
      text: "ISO 10002:2004",
    },
    {
      img: "/assets/images/qualityDocument/s_sertifika8.jpg",
      alt: "Marka Tescil Belgesi",
      text: "Marka Tescil Belgesi",
    },
    {
      img: "/assets/images/qualityDocument/s_sertifika9.jpg",
      alt: "UNI EN ISO 9001:2008",
      text: "UNI EN ISO 9001:2008",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Kalite Belgelerimiz - Otorapor Balgat</title>
        <meta
          name="description"
          content="Otorapor Balgat olarak, işimizi en üst standartlarda gerçekleştirmeye ve müşterilerimize en kaliteli hizmeti sunmaya büyük önem veriyoruz."
        />
        <link
          rel="canonical"
          href="https://www.otoraporbalgat.com/kalite-belgelerimiz"
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <h1 style={{ display: "none" }}>Kalite Belgelerimiz Otorapor</h1>
      <Banner url={"/kalite-belgelerimiz"} text={"KALİTE BELGELERİMİZ"} />
      <div className="section-block">
        <div className="container">
          <div class="section-heading">
            <h4>KALİTE BELGELERİMİZ</h4>
            <div class="section-heading-line-left"> </div>
          </div>
          <div className="row mt-50 justify-content-center">
            {imageArr.length > 0
              ? imageArr.map((key) => {
                  return (
                    <div class="col-md-4 col-sm-6 col-12">
                      <div class="project-grid">
                        <div class="project-grid-img">
                          <img
                            src={key.img}
                            alt={key.text}
                            widh="1024"
                            height="1024"
                          />
                        </div>
                        <div class="project-grid-overlay">
                          <h4>
                            <span>{key.text}</span>
                          </h4>
                          <p></p>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default QualityDocument;
