import Content from '../../components/price';

import Layout from '../../components/layout';
import Expertise from '../../components/dashboard/expertise-description';

function Dashboard() {
  return (
    <>
     <Layout>
    <Content/>

    <Expertise/>

    </Layout>

    </>
  );
}

export default Dashboard;
