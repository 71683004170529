import Content from "../../components/contact";
import Layout from "../../components/layout";
import Expertise from "../../components/dashboard/expertise-description";
import Banner from "../../components/banner/index";
import { Helmet } from "react-helmet";

function OtoMr() {
    const metaDescriptionTag = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute("content", "Oto MR Sistemi, araçların iç yapısını ve mekanik bileşenlerini detaylı bir şekilde incelemeye olanak tanıyan bir oto tomografi ve oto röntgen cihazıdır.");
      } else {
        const newMetaTag = document.createElement("meta");
        newMetaTag.name = "description";
        newMetaTag.content = "Oto MR Sistemi (Oto Emar), araçların iç yapısını ve mekanik bileşenlerini detaylı bir şekilde incelemeye olanak tanıyan bir oto tomografi ve oto röntgen cihazıdır.";
        document.head.appendChild(newMetaTag);
      }
  return (
    <>
      <Layout>
        <>
          <Helmet>
            <title> Oto Mr Sistemi Balgat (Oto Emar) / ANKARA
          </title>
           
            <link
              rel="canonical"
              href="https://www.otoraporbalgat.com/oto-mr-sistemi-ankara"
            />
            <link rel="icon" href="/favicon.ico" />
          </Helmet>

          <Banner
            url={"/oto-mr-sistemi-ankara"}
            text={"Oto Mr Sistemi Ankara"}
          />
          <div class="section-block">
            <div class="container">
              <div class="section-heading">
                <h2>Oto Mr Sistemi Ankara</h2>
                <div class="section-heading-line-left"></div>
              </div>
              <div class="row mt-30">
                <p>
                  <span class="s2">Oto Mr Sistemi Ankara (Oto Emar)</span>
                </p>
                <p>
                  <b><span class="s2">Oto MR Sistemi</span></b>
                  <span class="s3">
                    , ara&ccedil;ların i&ccedil; yapısını ve mekanik
                    bileşenlerini detaylı bir şekilde incelemeye olanak tanıyan
                    bir oto tomografi ve oto r&ouml;ntgen cihazıdır. Bu
                    teknoloji, ara&ccedil; servislerinde ve bakım merkezlerinde
                    kullanılmak &uuml;zere tasarlanmıştır.
                  </span>
                </p>
                <p>
                  <span class="s2">&Ouml;zellikleri</span>
                </p>
                <div class="s5">
                  <span class="s4">&bull; </span>
                  <span class="s2">
                    Y&uuml;ksek &Ccedil;&ouml;z&uuml;n&uuml;rl&uuml;k:
                  </span>
                  <span class="s3">
                    {" "}
                    Ara&ccedil;ların i&ccedil; yapısının ve par&ccedil;alarının
                    y&uuml;ksek &ccedil;&ouml;z&uuml;n&uuml;rl&uuml;kte
                    g&ouml;r&uuml;nt&uuml;lenmesini sağlar.
                  </span>
                </div>
                <div class="s5">
                  <span class="s4">&bull; </span>
                  <span class="s2">Hızlı Tarama:</span>
                  <span class="s3">
                    {" "}
                    Ara&ccedil;ların hızlı ve etkili bir şekilde taranmasına
                    olanak tanır.
                  </span>
                </div>
                <div class="s5">
                  <span class="s4">&bull; </span>
                  <span class="s2">Detaylı Analiz:</span>
                  <span class="s3">
                    {" "}
                    Motor, şasi, s&uuml;spansiyon ve diğer &ouml;nemli
                    bileşenlerin detaylı analizini yapabilir.
                  </span>
                </div>
                <div class="s5">
                  <span class="s4">&bull; </span>
                  <span class="s2">Sorun Tespiti:</span>
                  <span class="s3">
                    {" "}
                    Mekanik problemleri ve aşınma durumlarını hızlıca tespit
                    eder.
                  </span>
                </div>
                <p>
                  <span class="s2">Kullanım Alanları</span>
                </p>
                <div class="s5">
                  <span class="s4">&bull; </span>
                  <span class="s2">Ara&ccedil; Servisleri:</span>
                  <span class="s3">
                    {" "}
                    Ara&ccedil;ların bakım ve onarımlarında kullanılan detaylı
                    i&ccedil; inceleme sağlar.
                  </span>
                </div>
                <div class="s5">
                  <span class="s4">&bull; </span>
                  <span class="s2">Bakım Merkezleri:</span>
                  <span class="s3">
                    {" "}
                    Ara&ccedil;ların d&uuml;zenli kontrollerinde kullanılabilir.
                  </span>
                </div>
                <div class="s5">
                  <span class="s4">&bull; </span>
                  <span class="s2">Kaza Sonrası İnceleme:</span>
                  <span class="s3">
                    {" "}
                    Kaza ge&ccedil;irmiş ara&ccedil;ların i&ccedil; yapısal
                    hasarlarını tespit etmek i&ccedil;in kullanılabilir.
                  </span>
                </div>
                <p>
                  <span class="s2">Avantajları</span>
                </p>
                <div class="s5">
                  <span class="s4">&bull; </span>
                  <span class="s2">Hızlı ve Etkili:</span>
                  <span class="s3">
                    {" "}
                    Ara&ccedil;ların i&ccedil; yapısını hızlıca inceleyerek
                    bakım s&uuml;relerini kısaltır.
                  </span>
                </div>
                <div class="s5">
                  <span class="s4">&bull; </span>
                  <span class="s2">Doğru Tespit:</span>
                  <span class="s3">
                    {" "}
                    Mekanik arızaların ve yapı hasarlarının doğru bir şekilde
                    belirlenmesine yardımcı olur.
                  </span>
                </div>
                <div class="s5">
                  <span class="s4">&bull; </span>
                  <span class="s2">Gelişmiş Teknoloji:</span>
                  <span class="s3">
                    {" "}
                    Modern teknolojiler kullanarak daha hassas ve detaylı
                    inceleme yapar.
                  </span>
                </div>
                <p>
                  <span class="s2">Nasıl &Ccedil;alışır?</span>
                </p>
                <p>
                <b> <span class="s2">Oto MR Sistemi</span></b> 
                  <span class="s3">
                    , ara&ccedil;ların &ccedil;eşitli b&ouml;l&uuml;mlerini
                    r&ouml;ntgen ışınları veya benzer teknolojiler kullanarak
                    tarar. Bu tarama işlemi, i&ccedil; yapıların ve bileşenlerin
                    detaylı g&ouml;r&uuml;nt&uuml;lerini oluşturur. Elde edilen
                    g&ouml;r&uuml;nt&uuml;ler, ara&ccedil; servis teknisyenleri
                    tarafından analiz edilerek bakım veya onarım kararları
                    alınır.
                  </span>
                </p>
                <p>
                  <span class="s3">
                    Bu cihaz, ara&ccedil;ların bakım ve onarım
                    s&uuml;re&ccedil;lerini daha verimli ve hassas hale
                    getirmeyi hedefler.
                  </span>
                </p>
                <p>
                  <span class="s3">İletişime ge&ccedil;mek i&ccedil;in </span>
                  <a href="https://api.whatsapp.com/send/?phone=905424425060">
                    <span class="s6">tıklayı</span>
                    <span class="s6">n</span>
                    <span class="s6">ız</span>
                  </a>
                  <span class="s3">!</span>
                </p>
              </div>
            </div>
          </div>
        </>
      </Layout>
    </>
  );
}

export default OtoMr;
